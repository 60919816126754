import React, { useEffect, useState } from "react"
import { motion, useViewportScroll, useTransform } from "framer-motion"
import { useInView } from "react-intersection-observer"
import TextLoop from "react-text-loop"

// Components
import Layout from "../components/layout"
import SEO from "../components/seo"

// SVG
import Hero1Svg from "../components/svg/home/hero-1/hero-1-svg"
import Hero2Svg1 from "../components/svg/home/hero-2/hero-2-svg-1"
import Hero2Svg2 from "../components/svg/home/hero-2/hero-2-svg-2"
import WebDevelopmentSvg from "../components/svg/home/services/services-web-development-svg"
import MobileDevelopmentSvg from "../components/svg/home/services/services-mobile-development-svg"
import ConsultingSvg from "../components/svg/home/services/services-consulting-svg"
import TeamSvg from "../components/svg/home/team/team-photo-svg"
import BoxSvg from "../components/svg/home/tech-stack/tech-stack-box-svg"

// Images
import ImagePhoenix from "../components/images/tech-phoenix-image"
import ImageRuby from "../components/images/tech-ruby-image"
import ImageNode from "../components/images/tech-node-image"
import ImageJs from "../components/images/tech-js-image"

const variantsHeroOneHeading = {
  visible: {
    opacity: 1,
    y: 0,
    transition: {
      duration: 0.6,
    },
  },
  hidden: {
    opacity: 0,
    y: 50,
  },
}

const variantsHeroOneParagraph = {
  visible: {
    opacity: 1,
    y: 0,
    transition: {
      duration: 0.6,
    },
  },
  hidden: {
    opacity: 0,
    y: 20,
  },
}

const variantsHeroTwoHeading = {
  visible: {
    opacity: 1,
    y: 0,
    transition: {
      duration: 0.6,
    },
  },
  hidden: {
    opacity: 0,
    y: typeof window === "undefined" ? 0 : window.innerWidth <= 768 ? 40 : 80,
  },
}

const IndexPage = () => {
  const [companies, setCompanies] = useState(null)
  const [isMobile, setIsMobile] = useState(true)
  const [hero2Height, setHero2Height] = useState(
    typeof window === "undefined" ? 0 : window.innerWidth * 0.63988
  )
  const [refHeroTwoHeading, inViewHeroTwoHeading] = useInView({
    threshold: 1,
  })
  const { scrollY } = useViewportScroll()
  const yHeroOneHeading = useTransform(scrollY, [0, 480], [0, 100])
  const yHeroOneParagraph = useTransform(scrollY, [0, 480], [0, 75])
  const yServicesHeading = useTransform(
    scrollY,
    [700 + hero2Height, 1600 + hero2Height],
    [0, -350]
  )
  const yServicesWebDevelopment = useTransform(
    scrollY,
    [1200 + hero2Height, 2000 + hero2Height],
    [isMobile ? 0 : 100, -350]
  )
  const yServicesMobileDevelopment = useTransform(
    scrollY,
    [1600 + hero2Height, 2400 + hero2Height],
    [isMobile ? 0 : 100, -350]
  )
  const yServicesConsulting = useTransform(
    scrollY,
    [2200 + hero2Height, 3000 + hero2Height],
    [isMobile ? 0 : 100, -350]
  )
  const yTechStackBoxes = useTransform(
    scrollY,
    [4000 + hero2Height, 4400 + hero2Height],
    [isMobile ? 0 : -100, 0]
  )
  const yTechStackOuter = useTransform(
    scrollY,
    [4000 + hero2Height, 4400 + hero2Height],
    [isMobile ? 0 : 100, 0]
  )
  const yTechStackInner = useTransform(
    scrollY,
    [4500 + hero2Height, 4500 + hero2Height],
    [isMobile ? 0 : 0, 0]
  )
  const yLine = useTransform(
    scrollY,
    [5000 + hero2Height, 5500 + hero2Height],
    [50, 0]
  )
  const yUnicorn = useTransform(
    scrollY,
    [5000 + hero2Height, 5500 + hero2Height],
    [isMobile ? 0 : 50, 0]
  )

  useEffect(() => {
    const shuffle = array => {
      for (let i = array.length - 1; i > 0; i--) {
        let j = Math.floor(Math.random() * (i + 1))
        ;[array[i], array[j]] = [array[j], array[i]]
      }
      return array
    }

    setCompanies(
      shuffle([
        { name: "Twitter", color: "#00acee" },
        { name: "Facebook", color: "#3b5998" },
        { name: "Tinder", color: "#fe3c72" },
        { name: "Twitch", color: "#6441a5" },
        { name: "Google", color: "#4285F4" },
        { name: "Discord", color: "#7289da" },
        { name: "Airbnb", color: "#FF5A5F" },
        { name: "Zoom", color: "#2D8CFF" },
        { name: "Netflix", color: "#E50914" },
        { name: "Lazada", color: "#0F0183" },
        { name: "Spotify", color: "#1DB954" },
        { name: "PayPal", color: "#3b7bbf" },
        { name: "Udemy", color: "#ec5252" },
        { name: "Grab", color: "#1AAE48" },
        { name: "Uber", color: "#000000" },
        { name: "Lyft", color: "#FF00BF" },
      ])
    )

    if (window.innerWidth <= 768) {
      setIsMobile(true)
    } else {
      setIsMobile(false)
      setHero2Height(window.innerWidth * 0.63988)
    }

    const handleResize = () => {
      setHero2Height(window.innerWidth * 0.63988)
    }

    window.addEventListener("resize", handleResize)

    return () => {
      setCompanies(null)
      window.removeEventListener("resize", handleResize)
    }
  }, [])

  return (
    <Layout>
      <SEO
        title="Home"
        description="We pride ourselves in designing and building products that will push
          your brand into disrupting the game."
      />

      {/* Section - Hero 1 */}
      <div className="flex max-w-270 mx-10 xl:mx-auto">
        <div className="hidden absolute top-0 md:block w-px h-202 bg-gradient-to-b from-gray-2-start to-gray-2 z-10"></div>
        <div className="block md:flex pb-14 md:pb-12">
          <div className="w-full md:w-1/2 max-w-270 mt-6 md:my-auto">
            {companies && (
              <motion.h1
                style={{ y: isMobile ? 0 : yHeroOneHeading }}
                variants={variantsHeroOneHeading}
                initial="hidden"
                animate="visible"
                className="text-h3 md:text-largeHeading font-heading font-bold text-black"
              >
                How about becoming the next{" "}
                <TextLoop interval={850} className="-z-1">
                  {companies.map((company, index) => (
                    <span key={index} style={{ color: company.color }}>
                      {company.name}
                    </span>
                  ))}
                </TextLoop>
                ?
              </motion.h1>
            )}
            <motion.p
              style={{ y: isMobile ? 0 : yHeroOneParagraph }}
              variants={variantsHeroOneParagraph}
              initial="hidden"
              animate="visible"
              className="mt-6 text-regular font-paragraph font-normal text-black"
            >
              Or maybe something new? We're here to help.
            </motion.p>
          </div>
          <Hero1Svg
            isMobile={isMobile}
            className="w-full h-auto md:w-1/2 max-w-270 mt-6 md:my-auto md:pl-22"
          />
        </div>
      </div>

      {/* Section - Hero 2 */}
      <div className="relative max-w-270 mx-10 xl:mx-auto">
        <div className="absolute hidden md:block w-px md:h-155 lg:h-202 xl:h-230 2xl:h-250 bg-gray-2 -z-1"></div>
        <motion.h4
          ref={refHeroTwoHeading}
          variants={variantsHeroTwoHeading}
          initial="hidden"
          animate={inViewHeroTwoHeading ? "visible" : ""}
          className="max-w-4xl mx-auto pt-20 pb-10 md:py-25 text-h5 md:text-h4 font-heading font-medium text-black"
        >
          We pride ourselves in designing and building products that will push
          your brand into disrupting the game.
        </motion.h4>
      </div>

      <div className="grid md:grid-rows-1 md:grid-cols-12">
        <Hero2Svg1
          isMobile={isMobile}
          className="hidden md:block w-full h-auto row-span-3 row-start-2 col-start-1 md:col-span-7 md:col-start-1 md:row-start-1 order-2 md:order-1"
        />
        <Hero2Svg2
          isMobile={isMobile}
          className="w-full h-auto md:row-span-3 row-start-1 col-start-1 md:col-span-6 md:col-start-7 md:row-start-1 -z-1"
        />
      </div>

      {/* Section - Services */}
      <div className="relative max-w-270 mx-10 xl:mx-auto">
        <div className="hidden md:block absolute top-0 w-1/2 h-full border-r border-gray-2"></div>
        <div className="md:grid grid-cols-2 py-20 md:pt-75 md:pb-37">
          <motion.div style={{ y: isMobile ? 0 : yServicesHeading }}>
            <h1 className="text-h3 md:text-h1 font-heading font-bold text-black">
              Our Services
            </h1>
            <p className="mt-2.5 text-regular font-paragraph font-normal text-black">
              Our team is composed of professionals with wide array of
              experience and expertise across the spectrum from niche market to
              mainstream. As we are in the time of technology boom, we make sure
              that our products are highly-scalable to always meet demand.
            </p>
          </motion.div>
        </div>
        <div className="grid grid-rows-3 gap-y-20 md:gap-y-37">
          <motion.div
            style={{ y: isMobile ? 0 : yServicesWebDevelopment }}
            className="grid grid-cols-1 md:grid-cols-2"
          >
            <WebDevelopmentSvg className="w-full h-auto" />
            <div className="w-full mt-6 md:my-auto md:pl-22">
              <h3 className="text-h5 md:text-h3 font-heading font-bold text-black">
                Web Development
              </h3>
              <p className="mt-5 text-small md:text-regular font-paragraph font-normal text-black">
                We create <b>stunning, fast, secure</b> and{" "}
                <b>highly-scalable web applications</b> tailored exclusively for
                your business goals. It doesn’t matter if you’re building from
                scratch or revamping an existing one, we’ll{" "}
                <b>make it happen</b>.
              </p>
              <div className="mt-7 grid gap-7">
                {/* <div>
                  <p className="text-smallHeading font-paragraph font-medium tracking-widest text-black">
                    Projects
                  </p>
                  <p className="text-small font-paragraph font-medium text-brand">
                    Digital Ocean, Amazon, Google, Adobe
                  </p>
                </div> */}
                <div>
                  <p className="text-smallHeading font-paragraph font-medium tracking-widest text-black">
                    Technology
                  </p>
                  <p className="text-small font-paragraph font-bold text-brand">
                    Elixir & Phoenix, Ruby on Rails, NodeJS, React, Vue
                  </p>
                </div>
              </div>
            </div>
          </motion.div>
          <motion.div
            style={{ y: isMobile ? 0 : yServicesMobileDevelopment }}
            className="grid grid-cols-1 md:grid-cols-2"
          >
            <MobileDevelopmentSvg className="w-full h-auto md:order-2" />
            <div className="w-full mt-6 md:my-auto md:pr-22">
              <div className="mb-5">
                <h3 className="text-h5 md:text-h3 font-heading font-bold text-black">
                  Mobile Development
                </h3>
                <p>
                  <span className="text-small font-bold text-red-300">
                    Coming soon
                  </span>
                </p>
              </div>
              <p className="text-small md:text-regular font-paragraph font-normal text-black">
                Our design and development team will help you build a{" "}
                <b>responsive, secure, and appealing mobile application</b> for
                the people on-the-go.
              </p>
              <div className="mt-7 grid gap-7">
                {/* <div>
                  <p className="text-smallHeading font-paragraph font-medium tracking-widest text-black">
                    Projects
                  </p>
                  <p className="text-small font-paragraph font-medium text-brand">
                    Marketo, Splitfit, Dribbble, Netflix
                  </p>
                </div> */}
                <div>
                  <p className="text-smallHeading font-paragraph font-medium tracking-widest text-black">
                    Technology
                  </p>
                  <p className="text-small font-paragraph font-bold text-brand">
                    Objective-C, Swift, Java, Kotlin, React Native
                  </p>
                </div>
              </div>
            </div>
          </motion.div>
          <motion.div
            style={{ y: isMobile ? 0 : yServicesConsulting }}
            className="grid grid-cols-1 md:grid-cols-2"
          >
            <ConsultingSvg className="w-full h-auto" />
            <div className="w-full mt-6 md:my-auto md:pl-22">
              <h3 className="mb-5 text-h5 md:text-h3 font-heading font-bold text-black">
                Consulting
              </h3>
              <p className="text-small md:text-regular font-paragraph font-normal text-black">
                From <b>choosing the best platform</b> to run your application
                to <b>identifying and fixing bottlenecks</b> on your existing
                system or even making sure you <b>get the right people</b> for
                the job, our experts are here to help you get on track.
              </p>
              <br />
              <p className="text-small md:text-regular font-paragraph font-normal text-black">
                At UnicornBox, we are <b>huge Elixir fans</b>. Are you planning
                on moving to Elixir-land? We can also help your team get up to
                speed.
              </p>
              <div className="mt-7">
                <div>
                  <p className="text-smallHeading font-paragraph font-medium tracking-widest text-black">
                    Consultation Services
                  </p>
                  <p className="text-small font-paragraph font-medium text-brand">
                    System analysis, candidate screening, team training, etc.
                  </p>
                </div>
              </div>
            </div>
          </motion.div>
        </div>
      </div>

      {/* Section - Team */}
      <div className="relative h-20 md:h-75">
        <div className="absolute bottom-0 left-0 w-1/2 h-full md:border-r border-gray-2 -z-1"></div>
      </div>
      <div className="max-w-4xl border border-gray-2 p-10 md:p-22 mx-10 lg:mx-auto">
        <h4 className="text-h5 md:text-h4 font-heading font-normal text-black mb-6 md:mb-15">
          Our team is guided by our core purpose and values; complete
          transparency to our partners and continuous improvement of processes
          to help them accomplish their goals.
        </h4>
        <div className="flex">
          <TeamSvg className="w-full h-auto" />
        </div>
      </div>
      <div className="w-1/2 h-20 md:h-75 md:border-r border-gray-2"></div>

      {/* Section - Tech Stack */}
      <div className="relative">
        {/* Background */}
        <motion.div
          style={{ y: yTechStackBoxes }}
          className="hidden md:block absolute top-0 left-0 w-full h-full"
        >
          <div className="mt-32 mx-auto w-1/3 2xl:w-full max-w-screen-md flex justify-end">
            <BoxSvg />
          </div>
          <div className="mt-38 px-10 flex w-full max-w-screen-2xl mx-auto justify-end">
            <BoxSvg />
          </div>
          <div className="px-10 flex w-full max-w-screen-2xl mx-auto justify-start">
            <BoxSvg />
          </div>
          <div className="mt-38 mx-auto w-1/3 2xl:w-full max-w-screen-md flex justify-start">
            <BoxSvg />
          </div>
        </motion.div>

        {/* Line */}
        <div className="absolute top-0 left-0 w-full hidden md:block">
          <div className="h-50 w-1/2 border-r border-gray-2 -z-1"></div>
        </div>

        {/* Heading */}
        <div className="relative mx-10 xl:mx-auto xl:w-full max-w-6xl">
          <div className="lg:w-1/2">
            <h1 className="mb-2.5 text-h3 md:text-h1 font-heading font-bold text-black">
              Our Tech Stack
            </h1>
            <p className="mb-10 md:mb-15 text-regular font-paragraph font-normal text-black">
              With these technologies at our core, we help startups build high
              quality web and mobile apps.
            </p>
          </div>
        </div>

        {/* Tech Stack */}
        <div className="relative mx-10 md:mx-23 2xl:mx-auto max-w-screen-xl pb-10 md:pb-0 md:grid grid-cols-2 grid-rows-2">
          <motion.div
            style={{ y: isMobile ? 0 : yTechStackOuter }}
            className="mb-10 md:max-w-xs"
          >
            <ImagePhoenix className="w-12 h-12 mb-2" />
            <div className="ml-12">
              <h5 className="mb-0.5 text-h5 font-heading font-bold text-black">
                Elixir and Phoenix
              </h5>
              <p className="text-small font-paragraph font-normal text-black">
                Used by the likes of Discord and Whatsapp, Elixir and Phoenix
                framework will enable you to build scalable and maintainable
                applications.
              </p>
            </div>
          </motion.div>

          <motion.div
            style={{ y: isMobile ? 0 : yTechStackInner }}
            className="mb-10 md:max-w-xs"
          >
            <ImageRuby className="w-12 h-12 mb-2" />
            <div className="ml-12">
              <h5 className="mb-0.5 text-h5 font-heading font-bold text-black">
                Ruby on Rails
              </h5>
              <p className="text-small font-paragraph font-normal text-black">
                This web application framework written in Ruby helps large
                brands such as Airbnb and Github maintain their online
                platforms.
              </p>
            </div>
          </motion.div>

          <motion.div
            style={{ y: isMobile ? 0 : yTechStackInner }}
            className="mb-10 md:mb-0 md:max-w-xs place-self-end"
          >
            <ImageNode className="w-12 h-12 mb-2" />
            <div className="ml-12">
              <h5 className="mb-0.5 text-h5 font-heading font-bold text-black">
                NodeJS
              </h5>
              <p className="text-small font-paragraph font-normal text-black">
                Runtime environment that lets developer harness the capabilities
                of Javascript.
              </p>
            </div>
          </motion.div>

          <motion.div
            style={{ y: isMobile ? 0 : yTechStackOuter }}
            className="mb-10 md:mb-0 md:max-w-xs place-self-end"
          >
            <ImageJs className="w-12 h-12 mb-2" />
            <div className="ml-12">
              <h5 className="mb-0.5 text-h5 font-heading font-bold text-black">
                Typescript/JS/React/Vue
              </h5>
              <p className="text-small font-paragraph font-normal text-black">
                Powerful language and frameworks that will shape your web
                application into fruition.
              </p>
            </div>
          </motion.div>
        </div>

        {/* Line */}
        <motion.div
          style={{ y: isMobile ? 0 : yLine }}
          className="relative hidden md:block md:border-r border-gray-2 h-72 max-w-3xl mx-10 lg:mx-auto"
        ></motion.div>
      </div>

      {/* Section - Unicorn */}
      <motion.div
        style={{ y: isMobile ? 0 : yUnicorn }}
        className="hidden md:block md:border-r border-gray-2 h-2.5 max-w-3xl mx-10 lg:mx-auto"
      ></motion.div>
      <motion.div
        style={{ y: isMobile ? 0 : yUnicorn }}
        className="max-w-3xl mx-10 lg:mx-auto p-10 md:p-22 border border-gray-2"
      >
        <h4 className="md:mb-px text-h5 md:text-h4 font-bold font-heading text-black">
          u·ni·corn
        </h4>
        <p className="mb-6 text-small font-normal font-paragraph text-gray-7">
          /ˈyo͞onəˌkôrn/
        </p>
        <h4 className="text-h5 md:text-h4 font-normal font-heading text-black">
          A start-up company valued at more than a billion dollars, typically in
          the software or technology sector.
        </h4>
      </motion.div>
      <div className="md:border-l border-gray-2 h-20 md:h-28 max-w-3xl mx-10 lg:mx-auto"></div>
    </Layout>
  )
}

export default IndexPage

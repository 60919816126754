import * as React from "react"

function SvgComponent(props) {
  return (
    <svg
      width={720}
      height={491}
      viewBox="0 0 720 491"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M479.35 423.836c-81.736 0-150.97 7.208-174.654 17.163a2063.8 2063.8 0 00-21.279-.108c-51.386 0-97.836 1.865-131.13 4.87a457.602 457.602 0 01-28.483 1.674c-54.352 1.438-93.14 5.212-93.14 9.643 0 4.831 46.16 8.884 108.332 9.977 4.2.472 8.698.924 13.447 1.353-11.953 2.203-18.784 4.758-18.784 7.482 0 8.307 63.578 15.041 142.008 15.041 78.429 0 142.007-6.734 142.007-15.041 0-1.52-2.135-2.989-6.086-4.367 20.963 1.124 43.824 1.741 67.757 1.741 101.284 0 183.389-11.063 183.389-24.713s-82.101-24.715-183.384-24.715zM675.69 144.729c-15.138-12.517-28.233-27.413-38.421-44.208-26.343-43.432-83.594-73.53-149.988-73.53a213.854 213.854 0 00-35.184 2.892c-40.475 6.749-82.204 2.31-119.986-13.695C307.812 5.889 279.615 0 249.543 0 180.469 0 121.286 31.074 96.515 75.22c-15.016 26.757-33.247 51.675-55.853 72.422C15.276 170.938 0 200.928 0 233.662c0 74.167 78.47 134.291 175.269 134.291 20.679 0 40.521-2.743 58.939-7.782 39.779-10.886 81.751-11.919 121.517-.954 17.434 4.806 36.224 7.428 55.808 7.428 29.893 0 57.934-6.103 82.137-16.777 18.843 5.744 39.385 8.9 60.884 8.9 91.373 0 165.446-56.99 165.446-127.317 0-33.514-16.822-63.992-44.31-86.722z"
        fill="#DDDFFF"
      />
      <path
        d="M533.551 59.413H217.784v133.352h315.767V59.413z"
        fill="#C2C9FF"
      />
      <path d="M523.292 70.13H228.038v111.918h295.254V70.131z" fill="#F0F1FF" />
      <path
        d="M377.413 182.048h43.924l101.957-89.49V70.13h-19.637L377.413 182.048z"
        fill="#fff"
      />
      <path d="M306.236 70.13h-11.28v111.918h11.28V70.131z" fill="#C2C9FF" />
      <path
        d="M228.038 70.13l6.45 4.398h60.468V70.13h-66.918zM306.922 70.13l6.45 4.398h209.922V70.13H306.922z"
        fill="#A7B2FF"
      />
      <path
        d="M228.038 70.13l6.45 4.398v108.568h-6.45V70.131zM306.015 70.13l6.45 4.398v108.568h-6.45V70.131z"
        fill="#D6DAFD"
      />
      <path d="M636.663 333.526h-9.18v108.605h9.18V333.526z" fill="#23283F" />
      <path
        d="M666.798 310.437h-69.45c-6.167 0-11.167 5-11.167 11.168v.753c0 6.168 5 11.168 11.167 11.168h69.45c6.168 0 11.168-5 11.168-11.168v-.753c0-6.168-5-11.168-11.168-11.168z"
        fill="#334663"
      />
      <path
        d="M661.705 446.88h-59.264v-.271a7.998 7.998 0 017.998-7.998h43.268a7.998 7.998 0 017.998 7.998v.271zM611.621 446.88h-9.178v4.741a4.44 4.44 0 004.44 4.439h.301a4.437 4.437 0 004.44-4.439l-.003-4.741z"
        fill="#14192B"
      />
      <path
        d="M636.663 446.88h-9.178v4.741c0 1.177.468 2.306 1.301 3.138a4.436 4.436 0 003.139 1.301h.309a4.44 4.44 0 004.44-4.439l-.011-4.741zM661.705 446.88h-9.178v4.741c0 1.177.468 2.306 1.301 3.138a4.436 4.436 0 003.139 1.301h.301a4.438 4.438 0 004.439-4.439l-.002-4.741z"
        fill="#14192B"
      />
      <path d="M113.766 332.405h-9.18V441.01h9.18V332.405z" fill="#23283F" />
      <path
        d="M143.898 309.317h-69.45c-6.167 0-11.167 5-11.167 11.167v.754c0 6.167 5 11.167 11.168 11.167h69.449c6.168 0 11.168-5 11.168-11.167v-.754c0-6.167-5-11.167-11.168-11.167z"
        fill="#334663"
      />
      <path
        d="M138.806 445.761H79.544v-.271a8 8 0 014.937-7.389 7.999 7.999 0 013.06-.609h43.267a8.002 8.002 0 017.389 4.937c.402.971.609 2.011.609 3.061v.271zM88.722 445.761h-9.178v4.739a4.441 4.441 0 004.44 4.44h.298a4.442 4.442 0 004.44-4.44v-4.739zM113.768 445.761h-9.178v4.739a4.425 4.425 0 001.3 3.139 4.43 4.43 0 003.139 1.301h.299a4.44 4.44 0 004.44-4.44v-4.739zM138.805 445.761h-9.178v4.739a4.443 4.443 0 002.741 4.102 4.443 4.443 0 001.699.338h.301a4.443 4.443 0 003.138-1.301 4.428 4.428 0 001.299-3.139v-4.739z"
        fill="#14192B"
      />
      <path
        d="M654.006 101.725a20.519 20.519 0 00-20.51 19.935c-.1 3.535.715 7.035 2.367 10.161l-8.589 13.388 13.902-6.958a20.515 20.515 0 0018.482 3.726 20.52 20.52 0 00-5.652-40.252zM67.552 144.317a20.56 20.56 0 00-21.163-29.951 20.559 20.559 0 1015.85 36.38l13.902 6.958-8.589-13.387zM362.988 118.18l-6.499-10.132a15.56 15.56 0 10-4.022 4.866l10.521 5.266z"
        fill="#C2C9FF"
      />
      <path
        d="M619 131.117s.327 34.918 25.234 42.007c8.325 1.404 20.643 28.058-8.994 31.204-13.055 3.05-32.511-4.549-38.067-15.213-6.386-12.256-6.696-32.871-6.696-32.871L619 131.117z"
        fill="#151F4C"
      />
      <path
        d="M623.167 168.121c-8.143-.933-7.07-14.018-5.881-24.528 1.189-10.51-5.91-13.75-12.205-6.275-6.207 7.37-6.102-1.185-6.098-1.422-.619 4.532-.175 8.739 1.089 11.9l-2.384 2.1a42.175 42.175 0 003.722 6.436s5.763 22.162 20.83 26.884c15.068 4.721 9.07-14.165.927-15.095z"
        fill="#0F1945"
      />
      <path
        d="M511.077 223.796l-20.045-11.186-1.206-11.815 8.918-1.103 5.492 1.453 8.567 9.556 4.035 8.459-5.761 4.636zM492.618 213.254c-.376.152-8.38-4.164-8.38-4.164l-4.803-7.044a.538.538 0 01.608-.817 16.803 16.803 0 017.635 5.058l7.085 3.808-2.145 3.159z"
        fill="#FFACA6"
      />
      <path
        d="M491.237 208.012c-.378.151-8.38-4.162-8.38-4.162l-4.805-7.044a.544.544 0 01.014-.626.537.537 0 01.596-.192 16.814 16.814 0 017.633 5.057l7.085 3.808-2.143 3.159zM496.598 202.84c-.375.154-8.38-4.162-8.38-4.162l-4.802-7.044a.54.54 0 01.011-.627.537.537 0 01.597-.191 16.82 16.82 0 017.635 5.057l7.084 3.81-2.145 3.157z"
        fill="#FFACA6"
      />
      <path
        d="M496.129 200.567l7.494-1.573-.388-5.706a4.268 4.268 0 01.785-2.775.958.958 0 011.41-.156c.133.119.23.274.278.447l6.101 20.86-3.744 3.919-6.982-12.278-4.954-2.738zM491.973 204.528c-.376.154-8.38-4.162-8.38-4.162l-4.803-7.044a.534.534 0 01.011-.627.537.537 0 01.598-.19 16.828 16.828 0 017.635 5.056l7.084 3.811-2.145 3.156z"
        fill="#FFACA6"
      />
      <path
        d="M487.712 197.935l9.313 4.653s-6.992-2.627-9.313-4.653zM481.546 197.365l2.988 3.505 9.584 4.536-9.289-5.144-3.283-2.897zM481.54 201.922s3.093 3.085 4.86 4.199l5.635 2.427-5.635-3.149-4.86-3.477z"
        fill="#EA756C"
      />
      <path
        d="M491.715 208.947l.365-.641a22.698 22.698 0 014.326-5.455l2.338-2.163-3.97 4.718-3.059 3.541zM503.333 199.692l-1.311 2.751a4.765 4.765 0 00-.456 2.374l.202 3.146a4.806 4.806 0 001.281 2.958l1.394 1.494-.615-.484a7.947 7.947 0 01-2.122-2.559 7.948 7.948 0 01-.887-3.204 7.928 7.928 0 011.605-5.285l.909-1.191z"
        fill="#EA756C"
      />
      <path
        d="M589.651 185.512l-5.409 6.639-28.689 39.701c-2.03 2.809-1.522 6.813 1.178 9.287 2.83 2.594 9.91 1.616 12.476-.948l33.699-37.319c1.202-1.202 2.735-3.842 3.902-6.617 2.147-5.123.141-11.24-4.896-13.603a8.142 8.142 0 00-1.394-.512c-4.09-1.078-8.308.23-10.867 3.372zM617.771 268.772l-82.643 35.161 16.547 5.328 2.333 11.945 77.81-13.813-1.588-30.661-12.459-7.96z"
        fill="#FFACA6"
      />
      <path
        d="M529.779 311.609l-4.461 97.175-6.263 24.042h15.123l5.313-19.882 11.616-57.371a69.689 69.689 0 004.926-26.457l-.213-20.201-8.306-6.574-7.223.154a10.898 10.898 0 00-10.512 9.114z"
        fill="#F4867D"
      />
      <path
        d="M631.284 297.107c-24.909-.096-69.834.325-69.834.325l-13.447 7.846a25.547 25.547 0 00-12.032 16.399 25.5 25.5 0 00-.566 7.518l2.039 10.261a159.868 159.868 0 012.841 39.601l-1.857 35.099.881-.213.177-.993 11.616-57.371a69.678 69.678 0 004.931-26.463l-.085-8.254 75.87-13.469-.534-10.286z"
        fill="#F4867D"
      />
      <path
        d="M659.462 310.318a9.094 9.094 0 01-6.457 4.072l-84.216 11.004-20.756-14.528 68.912-42.094 34.579-7.31s9.257 46.822 7.938 48.856z"
        fill="#FFACA6"
      />
      <path
        d="M548.033 310.866l-.73.427a15.96 15.96 0 00-6.939 18.944l32.318 94.325.685 9.846 15.275 1.443-2.186-25.247-3.451-48.566a91.598 91.598 0 00-6.903-28.943l-6.831-16.269-21.238-5.96z"
        fill="#FFACA6"
      />
      <path
        d="M616.945 268.772l-66.08 28.466.726.589a18.585 18.585 0 0012.276 4.117c2.973-.092 6.19-.053 7.98.397 7.349 1.849 9.578 6.581 10.144 8.525 1.481 5.11 1.481 12.609 1.481 12.609l23.757-1.81a42.774 42.774 0 013.931-.735l42.122-5.867a11.313 11.313 0 009.646-12.741l-11.402-40.86-34.581 7.31z"
        fill="#151F4C"
      />
      <path
        d="M651.526 261.462l-33.951 8.73-11.072 7.291 7.144.196a66.415 66.415 0 0119.285 3.416 9.32 9.32 0 016.342 8.207 9.324 9.324 0 01-2.58 7.104 9.33 9.33 0 01-3.403 2.253l-17.983 6.854a81.496 81.496 0 01-29.042 5.349h-4.269c1.481 5.11 1.481 12.608 1.481 12.608l23.757-1.81a42.854 42.854 0 013.932-.734l41.887-5.836a11.68 11.68 0 005.091-1.908 11.347 11.347 0 004.79-10.864l-11.409-40.856z"
        fill="#0F1945"
      />
      <path
        d="M638.051 177.474l5.718 9.178-.888 16.286-.405 36.453 9.05 22.066-3.573 4.5c-6.175 7.769-16.53 11.287-25.968 8.215a23.575 23.575 0 01-1.401-.499l-7.187-2.156a4.108 4.108 0 01-2.892-3.389l-3.526-26.363-6.775-10.643a17.404 17.404 0 01-10.572-18.111l1.055-8.869c.4-3.363.31-6.766-.267-10.102l-1.441-8.325 4.342-3.904a18.142 18.142 0 019.899-4.508l15.01-1.846c4.309-.53 15.881.194 19.821 2.017z"
        fill="#526DFD"
      />
      <path
        d="M643.76 186.656l-5.718-9.178c-2.8-1.296-9.449-2.032-14.613-2.162l7.751 10.459-12.993 37.089-17.144 5.101 26.389 2.135 4.437 11.203 8.222 31.164a24.929 24.929 0 007.853-6.506l3.573-4.495-9.037-22.071.403-36.452.877-16.287z"
        fill="#415DF1"
      />
      <path
        d="M610.608 230.08l-6.039 2.692-40.66 20.015a5.466 5.466 0 00-2.988 5.449c.348 3.571 3.91 5.9 7.242 4.732l48.332-16.917a8.152 8.152 0 005.24-5.501l.132-.455a8.446 8.446 0 00-2.653-8.738 8.156 8.156 0 00-8.606-1.277zM565.619 226.255l-6.38-1.731-44.156-10.198a5.468 5.468 0 00-3.245.263 5.469 5.469 0 00-2.518 2.063c-1.989 2.989-.7 7.044 2.621 8.248l48.148 17.441a8.148 8.148 0 007.537-.946l.39-.271a8.441 8.441 0 003.479-8.44 8.15 8.15 0 00-5.876-6.429z"
        fill="#FFACA6"
      />
      <path
        d="M624.004 182.285l-2.869 8.064-13.727 47.023c-.971 3.325.854 6.928 4.222 8.354 3.537 1.494 9.878-1.797 11.437-5.074l19.246-46.454c.73-1.533 1.292-4.526 1.458-7.542.305-5.549-3.629-10.638-9.178-11.176a8.285 8.285 0 00-1.494-.017c-4.201.354-7.736 2.999-9.095 6.822zM573.252 432.826l-5.486 4.041a14.009 14.009 0 01-8.318 2.73c-2.418 0-5.3.956-7.53 1.887a10.402 10.402 0 00-5.516 5.443c-.444 1.02-.787 1.85-.787 1.85H592.3l-.907-8.943a12.66 12.66 0 00-3.027-7.008h-15.12.006z"
        fill="#FFACA6"
      />
      <path
        d="M591.399 439.834a12.652 12.652 0 00-3.026-7.008c-4.173 6.304-5.729 7.785-13.095 7.962a10.955 10.955 0 01-5.153-1.174 50.223 50.223 0 00-3.913-1.763 13.995 13.995 0 01-6.964 1.746c-2.824-.045-5.915.593-8.72 1.75a8.023 8.023 0 00-4.909 7.43h46.685l-.905-8.943z"
        fill="#14192B"
      />
      <path
        d="M592.307 454.154h-7.213l-.745-2.23-13.374-.175-9.356 2.405h-15.998v-5.377h46.686v5.377z"
        fill="#14192B"
      />
      <path
        d="M519.051 432.826l-5.483 4.041a14.016 14.016 0 01-8.312 2.73c-2.421 0-5.3.956-7.53 1.887a10.388 10.388 0 00-5.516 5.443c-.446 1.02-.788 1.85-.788 1.85h46.682l-.908-8.943a12.65 12.65 0 00-3.024-7.008h-15.123.002z"
        fill="#F4867D"
      />
      <path
        d="M537.198 439.834a12.65 12.65 0 00-3.024-7.008c-4.173 6.304-5.729 7.785-13.097 7.962a10.947 10.947 0 01-5.151-1.174 49.907 49.907 0 00-3.915-1.763 13.977 13.977 0 01-6.962 1.746c-2.824-.045-5.915.593-8.72 1.75a8.023 8.023 0 00-4.909 7.43h46.683l-.905-8.943z"
        fill="#14192B"
      />
      <path
        d="M538.106 454.154h-7.213l-.743-2.23-13.374-.175-9.356 2.405h-15.998v-5.377h46.684v5.377z"
        fill="#14192B"
      />
      <path
        d="M591.081 286.516l-34.931 14.019h.026l3.168.986c1.12.24 2.259.377 3.404.41l28.333-15.415z"
        fill="#0F1945"
      />
      <path
        d="M564.77 252.281l-23.465-.745 1.138 10.115 9.641.054 4.295 1.182c3.325.907 6.85.762 10.09-.416l4.525-1.646-6.224-8.544z"
        fill="#FFACA6"
      />
      <path
        d="M543.367 251.536c-.282-.258-9.338.54-9.338.54l-7.238 4.19a.477.477 0 00.226.897 18.503 18.503 0 009.018-1.548l8.013-.698-.681-3.381z"
        fill="#FFACA6"
      />
      <path
        d="M541.896 254.027c-.281-.258-9.34.54-9.34.54l-7.234 4.19a.477.477 0 00.226.897 18.503 18.503 0 009.021-1.548l8.017-.687-.69-3.392z"
        fill="#FFACA6"
      />
      <path
        d="M543.1 256.26c-.282-.261-9.338.538-9.338.538l-7.234 4.19a.477.477 0 00.226.896 18.49 18.49 0 009.018-1.547l8.013-.698-.685-3.379z"
        fill="#FFACA6"
      />
      <path
        d="M545.574 257.94c-.281-.259-9.34.54-9.34.54L529 262.67a.477.477 0 00.226.896 18.502 18.502 0 009.021-1.547l8.012-.698-.685-3.381z"
        fill="#FFACA6"
      />
      <path
        d="M530.475 261.812c1.801-1.281 3.677-2.453 5.577-3.589l.07-.04h.092c1.556-.109 3.114-.181 4.673-.233a63.588 63.588 0 014.683 0c-1.55.239-3.104.387-4.658.536-1.554.149-3.11.237-4.668.307l.162-.049c-1.931 1.076-3.897 2.126-5.931 3.068zM528.712 259.709c1.801-1.281 3.675-2.452 5.577-3.588l.071-.043h.092c1.556-.109 3.114-.181 4.672-.232a64.146 64.146 0 014.683 0c-1.55.239-3.104.384-4.657.535-1.554.152-3.11.235-4.669.306l.163-.047c-1.943 1.078-3.904 2.126-5.932 3.069zM528.438 256.96c1.802-1.281 3.676-2.453 5.578-3.588l.07-.043h.092a127.91 127.91 0 014.672-.233 65.318 65.318 0 014.684 0c-1.55.237-3.104.385-4.658.536-1.554.152-3.11.235-4.668.305l.162-.047c-1.936 1.078-3.897 2.126-5.932 3.07z"
        fill="#EA756C"
      />
      <path
        d="M608.247 184.776l-1.23.213a6.081 6.081 0 01-7.058-4.922l-4.79-26.811 17.204-3.074 4.077 22.839a10.144 10.144 0 01-8.203 11.755z"
        fill="#FFACA6"
      />
      <path
        d="M599.414 176.949a16.972 16.972 0 004.318-3.093c3.352-3.351 5.087-7.994 5.202-12.73l.214-9.021-13.734 2.453 4 22.391z"
        fill="#F4867D"
      />
      <path
        d="M592.658 140.8c10.421-3.688 17.275-11.179 15.31-16.731-1.965-5.552-12.005-7.063-22.426-3.375-10.42 3.688-17.275 11.179-15.31 16.731 1.965 5.552 12.006 7.063 22.426 3.375z"
        fill="#151F4C"
      />
      <path
        d="M588.983 172.868c-3.47-.824-5.874-3.001-7.788-6.156-2.617-4.314-3.738-9.375-3.616-14.421l.614-25.524 29.64.713-.611 25.285c-.134 5.637-2.2 11.159-6.19 15.155-1.566 1.567-4.988 3.357-7.169 4.41a7.367 7.367 0 01-4.88.538z"
        fill="#FFACA6"
      />
      <path
        d="M618.999 137.873c1.96-9.632-.864-18.338-6.308-19.445-5.443-1.108-11.444 5.803-13.404 15.435-1.959 9.631.865 18.337 6.308 19.445 5.443 1.107 11.444-5.803 13.404-15.435z"
        fill="#151F4C"
      />
      <path
        d="M617.293 143.572c1.178-10.493-5.915-13.725-12.205-6.254-6.049 7.182-6.105-.754-6.098-1.383-.026.19-.045.377-.066.567-.041.35-.086.703-.114 1.048-.01.133-.015.259-.023.389-.254 3.744.213 7.176 1.291 9.857 1.191 2.978 3.106 5.029 5.55 5.528 4.297.875 8.928-3.248 11.665-9.752z"
        fill="#0F1945"
      />
      <path
        d="M594.848 137.695c10.42-3.688 17.275-11.179 15.31-16.731-1.965-5.552-12.005-7.063-22.426-3.375-10.421 3.688-17.275 11.179-15.31 16.731 1.965 5.552 12.005 7.063 22.426 3.375z"
        fill="#151F4C"
      />
      <path
        d="M610.97 151.828c1.514-3.636.643-7.458-1.947-8.537-2.59-1.079-5.917.994-7.432 4.629-1.515 3.636-.643 7.458 1.946 8.537 2.59 1.079 5.918-.994 7.433-4.629z"
        fill="#FFACA6"
      />
      <path
        d="M609.072 150.685c.885-2.122.376-4.352-1.135-4.982-1.512-.63-3.454.58-4.338 2.702-.884 2.121-.376 4.352 1.136 4.982 1.511.63 3.453-.58 4.337-2.702z"
        fill="#F4867D"
      />
      <path
        d="M606.224 150.291c.626-1.501.365-3.037-.582-3.431-.946-.395-2.221.502-2.846 2.003-.625 1.5-.364 3.036.582 3.431.947.394 2.221-.502 2.846-2.003z"
        fill="#FFACA6"
      />
      <path
        d="M570.761 131.875s-1.055-15.761 24.169-16.092c-4.668-1.121-24.773.119-24.169 16.092zM617.417 124.325s2.036 5.059 4.824 18.448c2.787 13.39 9.265 23.907 9.265 23.907s-10.409-14.596-14.089-42.355z"
        fill="#151F4C"
      />
      <path d="M385.62 328.512h-9.953v117.755h9.953V328.512z" fill="#23283F" />
      <path
        d="M419.234 303.477h-77.183c-6.168 0-11.168 5-11.168 11.167v2.7c0 6.168 5 11.168 11.168 11.168h77.183c6.168 0 11.167-5 11.167-11.168v-2.7c0-6.167-4.999-11.167-11.167-11.167z"
        fill="#334663"
      />
      <path
        d="M412.771 451.416h-64.248v-.967a8.002 8.002 0 017.998-7.998h48.261a7.998 7.998 0 017.997 7.998l-.008.967zM358.467 451.416h-9.953v5.513a4.443 4.443 0 002.741 4.102 4.443 4.443 0 001.699.338h1.067a4.44 4.44 0 004.44-4.44l.006-5.513zM385.62 451.416h-9.951v5.513a4.443 4.443 0 002.741 4.102 4.443 4.443 0 001.699.338h1.067a4.44 4.44 0 004.44-4.44l.004-5.513zM412.771 451.416h-9.953v5.513a4.425 4.425 0 001.3 3.139 4.43 4.43 0 003.139 1.301h1.068a4.443 4.443 0 004.101-2.741 4.425 4.425 0 00.338-1.699l.007-5.513z"
        fill="#14192B"
      />
      <path
        d="M315.423 444.532l-5.944 3.415h-4.51a10.132 10.132 0 00-8.871 5.236l-1.306 2.369 2.36 1.836h46.946l5.368-6.425-23.989-11.934-10.054 5.503zM377.671 447.68l-2.345 6.295 2.345 4.896h33.222l.35-6.644-8.568-5.945-25.004 1.398z"
        fill="#14192B"
      />
      <path
        d="M419.689 186.259l33.53 71.346-14.042 5.818-33.121-49.379-1.99-19.896 15.623-7.889z"
        fill="#FFACA6"
      />
      <path
        d="M419.238 185.753l2.553 3.388 21.981 42.344-10.275 9.078-4.673 8.935-23.955-29.928v-16.655l14.369-17.162z"
        fill="#7C91FF"
      />
      <path
        d="M344.42 288.813l-23.052 12.866 36.713 17.661h19.584l35.17-1.748a23.795 23.795 0 003.573-16.31l-1.025-7.137-18.487-8.376-52.476 3.044z"
        fill="#1C2A6C"
      />
      <path
        d="M342.213 190.846l-44.442 51.023 13.279 12.643 36.169-29.853 5.146-25.719-10.152-8.094z"
        fill="#FFACA6"
      />
      <path
        d="M370.068 179.988L342 189.593l4.546 11.542.756 16.416a140.665 140.665 0 004.034 27.616l1.328 5.308c.578 2.317.852 4.699.815 7.087l-.16 10.578a27.533 27.533 0 01-5.439 16.009l-3.466 4.657 24.79 8.342a22.435 22.435 0 009.456 1.054l22.412-2.311a81.883 81.883 0 0113.342-.282l1.36.083-3.601-14.087a88.25 88.25 0 01-2.749-21.396l-.28-53.471 10.128-20.989-27.613-5.599-21.591-.162z"
        fill="#7C91FF"
      />
      <path
        d="M380.128 305.413a19.298 19.298 0 00-6.933 16.873l5.349 51.957-2.623 53.149 1.52 8.711a10.43 10.43 0 01-4.056 10.158 1.21 1.21 0 00-.254 1.682l3.138 4.28 7.125-2.53a22.905 22.905 0 017.661-1.317h5.477c2.639.004 5.257.464 7.739 1.36l6.972 2.501a8.811 8.811 0 001.054-10.593l-3.153-5.144 1.398-43.712-2.448-33.571 4.583-40.163a17.832 17.832 0 00-1.622-9.693l-.355-.488a19.843 19.843 0 00-18.305-8.062l-1.893.213a19.856 19.856 0 00-10.374 4.389zM321.368 301.679l-5.584 3.108a25.757 25.757 0 00-13.234 22.026l-.023 1.268a25.853 25.853 0 00.499 5.55l19.454 96.948a11.651 11.651 0 01-3.753 11.061l-3.312 2.898 6.644 1.401h4.252a25.949 25.949 0 019.04 1.622l12.593 4.672 2.688-2.241a11.59 11.59 0 003.767-11.921l-1.099-4.068a39.205 39.205 0 01-1.358-10.054l-.213-47.035a72.4 72.4 0 00-2.858-19.83l-6.875-23.75 1.4-5.945 14.687-8.042-6.995-17.661-29.72-.007z"
        fill="#293780"
      />
      <path
        d="M342.215 189.6l-30.382 33.509 10.835 8.051 5.773 9.463 25.693-17.787-3.449-24.767-8.47-8.469z"
        fill="#7C91FF"
      />
      <path
        d="M342 189.6l1.951 3.669a33.7 33.7 0 013.946 15.91c-.019 7.258.015 16.944.316 19.138-.463-4.555-1.28-15.174-1.566-21.605a33.873 33.873 0 00-2.043-10.292c-1.332-3.606-2.604-6.82-2.604-6.82zM419.285 185.753l-6.988 14.483a31.604 31.604 0 00-3.14 13.731v18.237l-.854-16.777a31.614 31.614 0 013.928-16.956l7.054-12.718z"
        fill="#5E77FD"
      />
      <path
        d="M400.521 441.648l-6.032-3.893a29.78 29.78 0 00-16.158-4.762h-.356v-10.229l3.931-13.223a45.401 45.401 0 00.376-24.546l-4.307-16.295.57 5.55-2.624 53.148 1.52 8.711a10.41 10.41 0 01-2.115 8.267l6.425-1.72a29.793 29.793 0 017.701-1.012l11.069.004zM358.087 319.34l-6.994-17.661h-2.562l-.514 3.115a28.438 28.438 0 01-24.581 23.586l-3.978.489 13.63 3.807a7.612 7.612 0 015.311 5.399l.493 1.88a77.74 77.74 0 011.921 9.951l3.037 24.109c.349 2.772.548 5.561.596 8.354l.448 25.951a28.58 28.58 0 01-12.57 24.169l-5.402 3.65 9.106.822a13.235 13.235 0 0111.231 8.621l-6.83-1.202-10.118 1.866.035.019c1.71.258 3.391.687 5.016 1.281l12.593 4.672 2.687-2.241a11.58 11.58 0 003.768-11.921l-1.1-4.068a39.203 39.203 0 01-1.357-10.054l-.214-47.035a72.4 72.4 0 00-2.858-19.83l-6.875-23.75 1.4-5.945 14.681-8.034z"
        fill="#1C2A6C"
      />
      <path
        d="M409.144 436.489l1.398-43.712-2.448-33.571 4.269-37.45-11.846 37.1 3.641 11.129a47.772 47.772 0 012.367 14.301l.263 22.331a21.044 21.044 0 01-7.928 16.707L395.501 426a16.597 16.597 0 0110.783 11.364l.626 2.276 2.928 4.743h-.807a39.18 39.18 0 00-16.262 3.534l-1.019.463h4.78a22.93 22.93 0 017.741 1.356l6.972 2.501a8.811 8.811 0 001.054-10.593l-3.153-5.155z"
        fill="#1C2A6C"
      />
      <path
        d="M395.586 159.386c1.909 0 3.456-6.853 3.456-15.306 0-8.454-1.547-15.307-3.456-15.307-1.908 0-3.456 6.853-3.456 15.307 0 8.453 1.548 15.306 3.456 15.306z"
        fill="#151F4C"
      />
      <path
        d="M370.623 154.769h18.63v27.456a8.084 8.084 0 01-8.083 8.083h-2.461a8.083 8.083 0 01-8.084-8.083v-27.456h-.002z"
        fill="#FFACA6"
      />
      <path
        d="M389.253 178.257a13.265 13.265 0 01-18.63 0v-23.479h18.63v23.479z"
        fill="#FB9B94"
      />
      <path
        d="M366.657 131.503h26.562V166.9a11.525 11.525 0 01-11.526 11.526h-3.509a11.529 11.529 0 01-11.527-11.526v-35.397z"
        fill="#FFACA6"
      />
      <path
        d="M381.75 144.487c9.96-2.111 17.221-7.658 16.218-12.389-1.003-4.73-9.89-6.853-19.85-4.741-9.961 2.111-17.222 7.658-16.219 12.388 1.003 4.731 9.89 6.854 19.851 4.742z"
        fill="#FB9B94"
      />
      <path
        d="M364.195 158.552c2.483-.202 3.941-7.196 3.257-15.621-.683-8.426-3.251-15.093-5.733-14.892-2.483.202-3.941 7.196-3.257 15.621.683 8.426 3.25 15.093 5.733 14.892z"
        fill="#151F4C"
      />
      <path
        d="M376.765 137.565c9.731-2.998 16.626-8.652 15.4-12.63-1.225-3.978-10.107-4.773-19.837-1.776-9.731 2.997-16.625 8.652-15.4 12.63 1.225 3.978 10.107 4.773 19.837 1.776z"
        fill="#151F4C"
      />
      <path
        d="M379.79 143.838c9.96-2.111 17.222-7.658 16.219-12.388-1.003-4.731-9.89-6.854-19.851-4.742-9.96 2.111-17.221 7.658-16.219 12.389 1.003 4.73 9.891 6.853 19.851 4.741z"
        fill="#151F4C"
      />
      <path
        d="M396.7 143.193c2.263-.321 3.538-4.527 2.847-9.394-.691-4.867-3.086-8.552-5.349-8.231-2.263.321-3.537 4.527-2.846 9.394.691 4.867 3.085 8.552 5.348 8.231z"
        fill="#151F4C"
      />
      <path
        d="M398.658 158.404c.692-3.461-.188-6.555-1.965-6.911-1.778-.355-3.781 2.162-4.473 5.623-.692 3.461.188 6.555 1.966 6.911 1.778.355 3.78-2.162 4.472-5.623z"
        fill="#FFACA6"
      />
      <path
        d="M397.327 157.841c.404-2.021-.11-3.827-1.148-4.035-1.038-.207-2.207 1.263-2.612 3.283-.404 2.021.11 3.827 1.148 4.035 1.038.207 2.207-1.262 2.612-3.283z"
        fill="#FB9B94"
      />
      <path
        d="M395.436 158.131c.286-1.429-.009-2.692-.659-2.822-.65-.13-1.408.923-1.694 2.352-.285 1.428.01 2.692.659 2.822.65.13 1.408-.923 1.694-2.352zM365.091 163.847c1.888-.378 2.834-3.606 2.113-7.21-.721-3.604-2.836-6.22-4.723-5.842-1.888.377-2.834 3.605-2.113 7.209.721 3.605 2.835 6.22 4.723 5.843z"
        fill="#FFACA6"
      />
      <path
        d="M364.537 160.822c1.101-.22 1.653-2.104 1.233-4.208-.421-2.104-1.655-3.631-2.757-3.41-1.102.22-1.654 2.104-1.233 4.208.421 2.104 1.655 3.631 2.757 3.41z"
        fill="#FB9B94"
      />
      <path
        d="M365.572 160.151c.69-.138 1.008-1.456.71-2.943-.297-1.488-1.098-2.582-1.788-2.444-.69.138-1.008 1.456-.711 2.943.298 1.488 1.099 2.582 1.789 2.444z"
        fill="#FFACA6"
      />
      <path
        d="M363.78 138.955s-2.254 1.957-9.133 0c3.464-.28 7.658-5.441 7.658-5.441l1.475 5.441z"
        fill="#151F4C"
      />
      <path
        d="M360.356 137.866s-1.944.589-6.138-.95c.559-.279 4.922-6.567 4.922-6.567l1.216 7.517zM396.67 128.003s6.047 5.904 2.931 15.308c1.078-1.314 4.713-8.412-2.931-15.308z"
        fill="#151F4C"
      />
      <path
        d="M381.693 122.031s-18.043-1.682-24.995 12.132c.128-3.874 8.849-14.205 24.995-12.132z"
        fill="#151F4C"
      />
      <path
        d="M302.437 238.849l1.159-.214a27.817 27.817 0 0112.807.671l44 12.892-10.717 6.768-45.155.214-3.039-.978a9.62 9.62 0 01-6.658-8.478l-.068-.969a9.39 9.39 0 017.671-9.906zM449.095 252.448l-1.013-.602a27.772 27.772 0 00-12.242-3.819l-45.737-3.202 7.696 10.073 42.263 15.893 3.189.141a9.631 9.631 0 009.191-5.637l.402-.884a9.39 9.39 0 00-3.749-11.963z"
        fill="#FFACA6"
      />
      <path
        d="M400.831 244.883l-18.545-14.391-5.024 8.854 7.769 5.71 2.775 3.481a16.688 16.688 0 008.41 5.593l4.627 1.327-.012-10.574z"
        fill="#FFACA6"
      />
      <path
        d="M383.953 231.705c-.077-.376-7.874-5.053-7.874-5.053l-8.324-.853a.473.473 0 00-.501.294.48.48 0 00.157.559 18.494 18.494 0 008.207 4.056l6.892 4.145 1.443-3.148z"
        fill="#FFACA6"
      />
      <path
        d="M381.3 232.855c-.077-.375-7.874-5.052-7.874-5.052l-8.325-.854a.476.476 0 00-.343.854 18.502 18.502 0 008.207 4.055l6.894 4.146 1.441-3.149z"
        fill="#FFACA6"
      />
      <path
        d="M380.963 235.367c-.077-.375-7.875-5.052-7.875-5.052l-8.324-.854a.476.476 0 00-.344.854 18.504 18.504 0 008.207 4.056l6.895 4.145 1.441-3.149z"
        fill="#FFACA6"
      />
      <path
        d="M381.972 238.179c-.076-.376-7.874-5.053-7.874-5.053l-8.324-.854a.473.473 0 00-.501.295.48.48 0 00.157.559 18.515 18.515 0 008.207 4.056l6.895 4.145 1.44-3.148z"
        fill="#FFACA6"
      />
      <path
        d="M367.484 232.445c2.209.024 4.416.175 6.617.374h.081l.079.049c1.323.825 2.629 1.679 3.916 2.561 1.281.893 2.562 1.783 3.791 2.75-1.394-.718-2.736-1.512-4.083-2.304-1.347-.791-2.655-1.637-3.957-2.495l.16.056c-2.201-.259-4.395-.564-6.604-.991zM367.287 229.698c2.21.026 4.417.177 6.617.376h.081l.079.049c1.324.826 2.626 1.684 3.917 2.561a67.077 67.077 0 013.791 2.75c-1.394-.718-2.736-1.512-4.083-2.304a90.463 90.463 0 01-3.958-2.495l.16.056c-2.2-.256-4.412-.559-6.604-.993zM368.688 227.323c2.209.025 4.416.177 6.616.375h.081l.079.049c1.324.826 2.629 1.679 3.917 2.562 1.281.892 2.562 1.782 3.791 2.749-1.394-.717-2.736-1.513-4.083-2.303s-2.655-1.637-3.958-2.495l.161.055c-2.201-.26-4.404-.566-6.604-.992z"
        fill="#FB9B94"
      />
      <path
        d="M355.407 250.509l23.441-1.197-.944 10.137-9.639.237-4.269 1.265a16.697 16.697 0 01-10.098-.213l-4.555-1.558 6.064-8.671z"
        fill="#FFACA6"
      />
      <path
        d="M376.788 249.353c.277-.265 9.349.36 9.349.36l7.315 4.056a.476.476 0 01-.214.901 18.509 18.509 0 01-9.048-1.375l-8.025-.542.623-3.4z"
        fill="#FFACA6"
      />
      <path
        d="M378.307 251.814c.278-.265 9.349.36 9.349.36l7.313 4.056a.472.472 0 01.248.534.475.475 0 01-.461.367 18.504 18.504 0 01-9.048-1.373l-8.024-.544.623-3.4z"
        fill="#FFACA6"
      />
      <path
        d="M377.146 254.068c.278-.265 9.349.36 9.349.36l7.313 4.056a.476.476 0 01-.213.901 18.51 18.51 0 01-9.049-1.373l-8.023-.551.623-3.393z"
        fill="#FFACA6"
      />
      <path
        d="M374.711 255.797c.277-.265 9.349.358 9.349.358l7.313 4.056a.474.474 0 01.248.534.48.48 0 01-.462.366 18.506 18.506 0 01-9.048-1.374l-8.026-.542.626-3.398z"
        fill="#FFACA6"
      />
      <path
        d="M389.876 259.378c-1.825-1.244-3.722-2.382-5.645-3.481l-.071-.041h-.091a128.041 128.041 0 00-4.677-.143c-1.56-.021-3.119 0-4.681.088 1.554.213 3.11.324 4.666.446 1.556.122 3.114.175 4.672.213l-.162-.044c1.957 1.048 3.949 2.057 5.989 2.962zM391.608 257.242c-1.828-1.245-3.725-2.382-5.648-3.482l-.071-.04h-.092a125.74 125.74 0 00-4.674-.143c-1.558-.022-3.121 0-4.683.087 1.554.214 3.11.325 4.666.446 1.556.122 3.114.175 4.674.214l-.164-.045c1.957 1.048 3.938 2.058 5.992 2.963zM391.821 254.486c-1.827-1.242-3.725-2.38-5.648-3.479l-.07-.041h-.092a127.932 127.932 0 00-4.675-.143c-1.558-.021-3.12 0-4.683.086 1.554.213 3.11.326 4.666.446a87.87 87.87 0 004.675.213l-.165-.045c1.958 1.048 3.939 2.054 5.992 2.963z"
        fill="#FB9B94"
      />
      <path
        d="M182.729 222.571l18.13-14.079-.589-11.863-8.984.254-5.21 2.264-7.029 10.739-2.713 8.971 6.395 3.714z"
        fill="#FFACA6"
      />
      <path
        d="M199.387 209.367c.394.094 7.656-5.379 7.656-5.379l3.688-7.684a.536.536 0 00-.107-.617.548.548 0 00-.618-.1 16.82 16.82 0 00-6.784 6.149l-6.422 4.841 2.587 2.79z"
        fill="#FFACA6"
      />
      <path
        d="M199.963 203.978c.394.094 7.656-5.379 7.656-5.379l3.688-7.684a.539.539 0 00-.725-.715 16.805 16.805 0 00-6.784 6.149l-6.429 4.833 2.594 2.796zM193.882 199.672c.395.094 7.656-5.378 7.656-5.378l3.688-7.685a.536.536 0 00-.107-.616.543.543 0 00-.618-.101 16.82 16.82 0 00-6.784 6.152l-6.431 4.832 2.596 2.796z"
        fill="#FFACA6"
      />
      <path
        d="M194.003 197.354l-7.645-.426-.476-5.702a4.286 4.286 0 00-1.193-2.627.946.946 0 00-.984-.239.947.947 0 00-.643.781l-2.886 21.541 4.291 3.311 5.052-13.187 4.484-3.452zM198.708 200.642c.397.093 7.658-5.379 7.658-5.379l3.689-7.685a.536.536 0 00-.401-.765.536.536 0 00-.325.05 16.827 16.827 0 00-6.786 6.15l-6.429 4.832 2.594 2.797z"
        fill="#FFACA6"
      />
      <path
        d="M201.922 193.485l-8.504 6.002s6.519-3.652 8.504-6.002zM207.937 191.993l-2.427 3.914-8.79 5.93 8.408-6.487 2.809-3.357zM208.626 196.503s-2.593 3.515-4.172 4.883l-5.206 3.249 5.097-3.961 4.281-4.171z"
        fill="#FB9B94"
      />
      <path
        d="M199.632 204.975l-.459-.581a22.723 22.723 0 00-5.099-4.741l-2.636-1.786 4.646 4.055 3.548 3.053zM186.75 197.574l1.708 2.523c.459.678.738 1.462.809 2.278l.273 3.14a4.798 4.798 0 01-.813 3.118l-1.151 1.693.536-.572a7.942 7.942 0 00-.275-11.125l-1.087-1.055z"
        fill="#FB9B94"
      />
      <path
        d="M122.487 184.197l30.526 60.389-15.979 8.986-27.716-37.833 1.34-26.195 11.829-5.347z"
        fill="#FFACA6"
      />
      <path
        d="M113.954 222.065l15.708 21.443 10.072-25.193-12.531-24.79-8.481 4.342-4.768 24.198z"
        fill="#FB9B94"
      />
      <path
        d="M135.847 241.41l.668-.971a27.804 27.804 0 019.514-8.596l40.194-22.059-2.775 12.38-31.731 32.124-2.837 1.464a9.627 9.627 0 01-10.704-1.281l-.737-.64a9.395 9.395 0 01-1.592-12.421z"
        fill="#FFACA6"
      />
      <path
        d="M125.147 183.54l16.25 30.617-12.474 5.14-12.506 12.23-15.326-29.842a12.528 12.528 0 01-.909-9.135l1.85-6.547a12.527 12.527 0 0123.115-2.463z"
        fill="#151F4C"
      />
      <path
        d="M225.442 435.631l5.94 3.415h4.51a10.132 10.132 0 018.867 5.247l1.304 2.369-2.363 1.834-46.946-.043-5.361-6.431 24-11.911 10.049 5.52z"
        fill="#14192B"
      />
      <path
        d="M96.506 311.048l81.585.999 22.132-20.173-66.732-25.898-29.245 26.015-7.74 19.057z"
        fill="#1F2E77"
      />
      <path
        d="M200.215 291.874l5.891 2.478a25.756 25.756 0 0115.561 20.442l.162 1.257c.238 1.845.276 3.711.113 5.565l-8.702 98.495a11.641 11.641 0 001.049 5.955 11.642 11.642 0 003.892 4.628l3.611 2.519-6.45 2.119-4.226.468a25.93 25.93 0 00-8.807 2.604l-11.998 6.023-2.918-1.931a11.58 11.58 0 01-4.201-4.979 11.578 11.578 0 01-.851-6.458l.64-4.164c.521-3.356.604-6.764.248-10.141l-4.95-46.775a72.516 72.516 0 01.664-20.024l4.228-24.361-2.043-5.763-15.479-6.384 5.014-18.32 29.552-3.253z"
        fill="#1F2E77"
      />
      <path
        d="M78.68 303.861l4.031 6.233 78.202 9.069 16.696-19.84-57.484-31.067-23.887 10.012-14.547 11.753-3.012 13.84z"
        fill="#293780"
      />
      <path
        d="M116.863 177.239l3.898 2.651a17.244 17.244 0 017.47 15.699l-2.988 25.234c-.111 1.33.214 9.921.427 11.238l-2.218 24.043 2.948 1.334a8.11 8.11 0 014.715 6.51l.019.19 4.961 2.135-1.383 9.855-7.448.053a23.6 23.6 0 00-6.971 1.106l-10.177 3.231a59.551 59.551 0 00-28.922 20.116l-2.513 3.216-3.378-1.396a6.698 6.698 0 01-4.129-6.657l.368-5.336-4.434-37.584a173.73 173.73 0 01-.95-29.67l-2.572-26.775c.199-3.722 5.416-16.369 5.416-16.369 2.648-3.526 11.69-4.578 15.985-5.582l13.71-1.398 18.166 4.156z"
        fill="#151F4C"
      />
      <path
        d="M85.335 191.267l-15.112 65.958-18.035-3.3 2.86-46.812 17.77-19.291 12.517 3.445z"
        fill="#FFACA6"
      />
      <path
        d="M76.856 228.27l1.251-5.462-13.801-18.858-6.43.092-2.828 3.069-1.995 32.679 23.803-11.52z"
        fill="#FB9B94"
      />
      <path
        d="M60.248 245.293l1.161-.214a27.823 27.823 0 0112.807.722l43.947 13.071-10.745 6.724-45.155.023-3.035-.988a9.624 9.624 0 01-6.617-8.506l-.066-.969a9.393 9.393 0 017.703-9.863z"
        fill="#FFACA6"
      />
      <path
        d="M87.8 192.462L80.733 226.4l-12.884-4.025-17.437 1.417 7.283-32.756a12.521 12.521 0 015.138-7.609l5.607-3.842a12.53 12.53 0 0115.042.64 12.53 12.53 0 014.318 12.237z"
        fill="#151F4C"
      />
      <path
        d="M180.849 440.748l5.942 3.415h4.51a10.13 10.13 0 018.865 5.244l1.306 2.369-2.363 1.836-46.946-.045-5.364-6.431 24.003-11.915 10.047 5.527z"
        fill="#14192B"
      />
      <path
        d="M175.028 297.889l5.582 3.114a25.753 25.753 0 0113.203 22.036l.024 1.268a25.72 25.72 0 01-.506 5.55l-19.541 96.929a11.654 11.654 0 003.739 11.065l3.311 2.903-6.645 1.392h-4.251a25.965 25.965 0 00-9.04 1.615l-12.593 4.66-2.688-2.243a11.584 11.584 0 01-3.756-11.926l1.103-4.055a39.098 39.098 0 001.366-10.052l.256-47.035a72.505 72.505 0 012.875-19.827l6.897-23.744-1.392-5.947-14.681-8.055 7.01-17.655 29.727.007z"
        fill="#293780"
      />
      <path
        d="M140.673 316.817l-57.962-6.723-4.032-6.233 4.888-6.058 7.685-7.257 5.432 4.423a53.798 53.798 0 0024.365 11.229c13.176 2.391 31.622 6.124 33.759 8.628a28.426 28.426 0 0018.143 9.767l3.979.493-13.635 3.795a7.61 7.61 0 00-5.315 5.394l-.495 1.879a77.694 77.694 0 00-1.921 9.948l-3.061 24.107a78.074 78.074 0 00-.604 8.355l-.472 25.951a28.584 28.584 0 0012.549 24.179l5.398 3.656-9.106.812a13.234 13.234 0 00-11.24 8.61l6.83-1.195 10.118 1.876-.035.017a26.006 26.006 0 00-5.018 1.281l-12.593 4.659-2.688-2.243a11.592 11.592 0 01-3.756-11.925l1.103-4.056a39.053 39.053 0 001.366-10.051l.257-47.036a72.428 72.428 0 012.875-19.827l6.896-23.744-1.391-5.947-12.319-6.764z"
        fill="#1F2E77"
      />
      <path
        d="M101.142 184.475h.573a8.47 8.47 0 003.234-.643 8.462 8.462 0 004.573-4.575 8.452 8.452 0 00.643-3.235v-24.883H92.69v24.883a8.452 8.452 0 008.452 8.453z"
        fill="#FFACA6"
      />
      <path
        d="M94.822 164.545v-12.743h15.343V176.4c0 .088 0 .171-.013.258a12.41 12.41 0 01-10.61-2.328 12.42 12.42 0 01-4.72-9.785z"
        fill="#FB9B94"
      />
      <path
        d="M100.921 169.051l2.014 1.539a12.057 12.057 0 007.315 2.472h.641c2.08 0 4.118-.588 5.878-1.697a11.026 11.026 0 004.071-4.568 16.4 16.4 0 001.596-6.446l.301-7.778.786-5.96c.175-1.334.24-2.682.194-4.027l-.453-13.283H95.138v28.06a14.694 14.694 0 005.783 11.688z"
        fill="#FFACA6"
      />
      <path
        d="M123.336 132.898c1.972-4.876-3.957-11.872-13.243-15.628-9.286-3.755-18.412-2.848-20.384 2.028-1.972 4.875 3.958 11.871 13.244 15.627 9.286 3.756 18.412 2.848 20.383-2.027z"
        fill="#FB9B94"
      />
      <path
        d="M94.514 152.211c4.564-1.099 6.516-9.255 4.358-18.216-2.159-8.962-7.608-15.335-12.173-14.236-4.565 1.1-6.516 9.255-4.358 18.217 2.158 8.961 7.608 15.334 12.173 14.235z"
        fill="#151F4C"
      />
      <path
        d="M94.837 152.099c-4.482 1.437-10-4.623-12.328-13.537-1.238-4.738-1.315-9.317-.453-12.794 3.005-1.17 2.918 4.35 6.534 9.392 3.334 4.642 8.11 1.558 10.459-.453 1.823 8.389.042 16.028-4.212 17.392z"
        fill="#0F1945"
      />
      <path
        d="M126.447 131.296c2.083-5.15-4.942-12.85-15.69-17.197-10.748-4.347-21.15-3.695-23.233 1.456-2.083 5.15 4.941 12.849 15.69 17.196 10.748 4.347 21.149 3.696 23.233-1.455z"
        fill="#151F4C"
      />
      <path
        d="M94.09 156.166c2.59-1.079 3.461-4.901 1.947-8.536-1.515-3.636-4.843-5.708-7.433-4.629-2.59 1.079-3.461 4.901-1.946 8.536 1.515 3.636 4.842 5.708 7.432 4.629z"
        fill="#FFACA6"
      />
      <path
        d="M92.892 153.099c1.512-.63 2.02-2.861 1.136-4.983-.884-2.122-2.826-3.331-4.338-2.701-1.511.629-2.02 2.86-1.135 4.982.884 2.122 2.826 3.332 4.337 2.702z"
        fill="#FB9B94"
      />
      <path
        d="M94.247 152.004c.947-.395 1.208-1.931.582-3.431-.625-1.501-1.9-2.398-2.846-2.003-.947.394-1.207 1.93-.582 3.431.625 1.501 1.9 2.397 2.846 2.003z"
        fill="#FFACA6"
      />
      <path
        d="M120.218 135.326l.72-.147a20.35 20.35 0 009.722-4.975c.882-.814 1.526-1.407 1.435-1.315-.092.091-.733.213-1.542.331a19.26 19.26 0 01-5.991-.022c-1.165-.192-2.425-.286-2.807.113-.762.794-1.537 6.015-1.537 6.015z"
        fill="#151F4C"
      />
      <path
        d="M118.231 130.204l.73.079a20.347 20.347 0 0010.775-1.785c1.087-.506 1.881-.875 1.767-.815-.113.059-.762-.022-1.568-.154a19.273 19.273 0 01-5.704-1.842c-1.05-.538-2.222-1.012-2.708-.745-.969.536-3.292 5.262-3.292 5.262z"
        fill="#151F4C"
      />
      <path
        d="M106.349 257.419l23.44-1.198-.943 10.137-9.639.237-4.269 1.266a16.675 16.675 0 01-10.096-.214l-4.558-1.558 6.065-8.67z"
        fill="#FFACA6"
      />
      <path
        d="M127.732 256.251c.277-.264 9.349.361 9.349.361l7.313 4.055a.48.48 0 01.248.535.48.48 0 01-.462.366 18.483 18.483 0 01-9.048-1.374l-8.026-.543.626-3.4z"
        fill="#FFACA6"
      />
      <path
        d="M129.249 258.714c.278-.264 9.349.359 9.349.359l7.313 4.056a.476.476 0 01-.213.9 18.497 18.497 0 01-9.049-1.372l-8.025-.544.625-3.399z"
        fill="#FFACA6"
      />
      <path
        d="M128.088 260.966c.278-.262 9.349.361 9.349.361l7.313 4.056a.476.476 0 01-.213.9 18.492 18.492 0 01-9.048-1.372l-8.026-.544.625-3.401z"
        fill="#FFACA6"
      />
      <path
        d="M125.653 262.695c.277-.264 9.349.361 9.349.361l7.313 4.056a.474.474 0 01.248.534.48.48 0 01-.462.366 18.498 18.498 0 01-9.046-1.374l-8.025-.543.623-3.4z"
        fill="#FFACA6"
      />
      <path
        d="M140.82 266.277c-1.827-1.245-3.724-2.382-5.647-3.479l-.071-.041h-.092a121.545 121.545 0 00-4.674-.141c-1.558-.019-3.121 0-4.683.086 1.554.213 3.11.326 4.666.446 1.556.119 3.114.177 4.674.213l-.162-.045c1.955 1.046 3.936 2.056 5.989 2.961zM142.549 264.14c-1.827-1.244-3.724-2.382-5.645-3.481l-.073-.041h-.092a123.479 123.479 0 00-4.674-.141c-1.558-.019-3.121 0-4.683.086 1.554.213 3.11.324 4.666.446 1.556.122 3.116.175 4.674.213l-.162-.044c1.955 1.048 3.936 2.057 5.989 2.962zM142.763 261.387c-1.827-1.245-3.725-2.382-5.646-3.482l-.07-.04h-.094a125.662 125.662 0 00-4.675-.143 67.8 67.8 0 00-4.683.087c1.554.214 3.11.325 4.666.446 1.556.122 3.117.176 4.675.214l-.163-.045c1.956 1.048 3.936 2.058 5.99 2.963z"
        fill="#FB9B94"
      />
      <path
        d="M71.86 177.937s23.336-1.355 10.849 38.965l-1.977 9.494s5.05-26.254 6.1-29.293c1.051-3.04 2.024-22.769-14.973-19.166zM125.245 220.823l2.561-28.056s1.467 18.547-2.561 28.056z"
        fill="#0C153D"
      />
      <path
        d="M199.361 289.824l.04.03a33.825 33.825 0 0114.122 27.49v138.859h12.489V316.847a33.821 33.821 0 0113.484-27.023h-40.135z"
        fill="#14192B"
      />
      <path
        d="M191.939 289.824l.041.03a33.829 33.829 0 0114.124 27.49v138.859h12.486V316.847a33.833 33.833 0 0113.486-27.023h-40.137z"
        fill="#23283F"
      />
      <path
        d="M557.203 289.824l-.04.03a33.817 33.817 0 00-14.122 27.49v138.859h-12.489V316.847a33.821 33.821 0 00-13.484-27.023h40.135z"
        fill="#14192B"
      />
      <path
        d="M564.627 289.824l-.041.03a33.819 33.819 0 00-14.124 27.49v138.859h-12.487V316.847a33.818 33.818 0 00-13.485-27.023h40.137z"
        fill="#23283F"
      />
      <path
        d="M592.96 283.033H164.742v-.587a17.304 17.304 0 0117.304-17.303h393.599a17.3 17.3 0 0117.302 17.303l.013.587z"
        fill="#334663"
      />
      <path
        d="M584.3 289.825H173.395a8.655 8.655 0 01-8.653-8.652v-.292H592.96v.292a8.658 8.658 0 01-2.537 6.121 8.647 8.647 0 01-6.123 2.531z"
        fill="#23283F"
      />
      <path
        d="M382.032 265.76l-1.507-62.6h93.717l-6.771 62.6h-85.439z"
        fill="#F0F1FF"
      />
      <path d="M474.242 203.16l-92.21 62.6h85.439l6.771-62.6z" fill="#E9EAFF" />
      <path
        d="M380.525 203.16l-3.063-1.82h91.011l5.769 1.82h-93.717z"
        fill="#C1C3ED"
      />
      <path
        d="M377.462 201.34l1.772 64.42h2.798l-1.507-62.6-3.063-1.82z"
        fill="#A4A7E1"
      />
      <path
        d="M380.525 265.76h-45.027v-2.117l45.027-.378v2.495z"
        fill="#A4A7E1"
      />
      <path
        d="M335.498 263.643l43.501-1.765.747 1.765h-44.248z"
        fill="#F0F1FF"
      />
      <path
        d="M426.512 239.444a4.572 4.572 0 10.001-9.145 4.572 4.572 0 00-.001 9.145z"
        fill="#fff"
      />
    </svg>
  )
}

export default SvgComponent

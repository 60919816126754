import * as React from "react"
import { motion, useViewportScroll, useTransform } from "framer-motion"

const variantsSvg = {
  visible: {
    opacity: 1,
    y: 0,
    transition: {
      duration: 0.6,
    },
  },
  hidden: {
    opacity: 0,
    y: 80,
  },
}

function SvgComponent({ isMobile, ...props }) {
  const { scrollY } = useViewportScroll()
  const y = useTransform(scrollY, [0, 600], [0, -100])
  const xDrone1 = useTransform(scrollY, [0, 600], [0, -100])
  const yDrone2 = useTransform(scrollY, [0, 600], [0, -50])
  const xDrone3 = useTransform(scrollY, [0, 600], [0, -50])

  return (
    <motion.svg
      style={{ y: isMobile ? 0 : y }}
      variants={variantsSvg}
      initial="hidden"
      animate="visible"
      width={451}
      height={602}
      viewBox="0 0 451 602"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path fill="#fff" d="M0 0h450v602H0z" />
      <path
        d="M0 375.778l225 25.778V602L0 576.222V375.778z"
        fill="url(#hero-1-svg-prefix__paint0_linear)"
      />
      <path
        d="M225 401.556l225-25.778v200.444L225 602V401.556z"
        fill="url(#hero-1-svg-prefix__paint1_linear)"
      />
      <path
        d="M225 401.555l224.999-25.777L225 350 0 375.778l225 25.777z"
        fill="#A7B2FF"
      />
      {/* Screen 3 Start */}
      <rect
        width={100}
        height={103}
        rx={3}
        transform="matrix(.99307 .11754 0 1 351 206)"
        fill="url(#hero-1-svg-prefix__paint2_linear)"
      />
      <circle
        r={2}
        transform="matrix(.99307 .11754 0 1 359.938 216.058)"
        fill="#F4B24D"
      />
      <circle
        r={2}
        transform="matrix(.99307 .11754 0 1 367.882 216.998)"
        fill="#F4B24D"
      />
      <circle
        r={2}
        transform="matrix(.99307 .11754 0 1 375.827 217.938)"
        fill="#F4B24D"
      />
      <rect
        width={21}
        height={2}
        rx={1}
        transform="matrix(.99307 .11754 0 1 366.889 228.88)"
        fill="#F4B24D"
      />
      <rect
        width={30}
        height={2}
        rx={1}
        transform="matrix(.99307 .11754 0 1 360.931 240.175)"
        fill="#fff"
      />
      <rect
        width={10}
        height={2}
        rx={1}
        transform="matrix(.99307 .11754 0 1 360.931 276.175)"
        fill="#fff"
      />
      <rect
        width={50}
        height={2}
        rx={1}
        transform="matrix(.99307 .11754 0 1 365.896 264.763)"
        fill="#F4B24D"
      />
      <rect
        width={10}
        height={2}
        rx={1}
        transform="matrix(.99307 .11754 0 1 370.861 253.351)"
        fill="#F4B24D"
      />
      <rect
        width={40}
        height={2}
        rx={1}
        transform="matrix(.99307 .11754 0 1 360.931 288.175)"
        fill="#fff"
      />
      <rect
        width={37}
        height={2}
        rx={1}
        transform="matrix(.99307 .11754 0 1 397.674 232.524)"
        fill="#fff"
      />
      <rect
        width={10}
        height={2}
        rx={1}
        transform="matrix(.99307 .11754 0 1 400.653 244.877)"
        fill="#F4B24D"
      />
      <rect
        width={30}
        height={2}
        rx={1}
        transform="matrix(.99307 .11754 0 1 380.792 278.526)"
        fill="#F4B24D"
      />
      <rect
        width={30}
        height={2}
        rx={1}
        transform="matrix(.99307 .11754 0 1 390.723 255.701)"
        fill="#fff"
      />
      <rect
        width={10}
        height={2}
        rx={1}
        transform="matrix(.99307 .11754 0 1 410.584 294.052)"
        fill="#F4B24D"
      />
      <rect
        width={20}
        height={2}
        rx={1}
        transform="matrix(.99307 .11754 0 1 420.515 247.228)"
        fill="#fff"
      />
      <rect
        width={10}
        height={2}
        rx={1}
        transform="matrix(.99307 .11754 0 1 420.515 283.228)"
        fill="#fff"
      />
      {/* Screen 3 End */}
      {/* Screen 1 Start */}
      <path
        d="M0 197.922c0-9.547 6.763-9.42 12.594-10.449l183.518-21.887c7.236-.53 9.888 5.096 9.888 7.586v112.721c0 2.49-6.514 10.157-8.986 10.45L8.986 319.131C6.514 319.424 0 317.004 0 313.348V197.922z"
        fill="#C0C8FD"
      />
      <path
        d="M4.509 197.922c0-2.49 2.004-4.746 4.477-5.039l192.536-22.788c2.473-.293 4.478 1.489 4.478 3.979v117.23c0 2.49-2.005 4.746-4.478 5.038L8.986 319.131c-2.473.292-4.477-1.489-4.477-3.979v-117.23z"
        fill="#E0E3FE"
      />
      <g clipPath="url(#hero-1-svg-prefix__clip0)">
        <rect
          width={192.076}
          height={115.426}
          rx={2}
          transform="matrix(.99307 -.11754 0 1 9.882 198.188)"
          fill="#C0C8FD"
        />
        <path
          fill="#929FFB"
          d="M9.882 198.188l53.731-6.36v115.427l-53.73 6.359z"
        />
        <path
          fill="#A6B1FF"
          d="M63.613 191.829l137.014-16.217v12.625L63.613 204.454z"
        />
        <circle
          transform="matrix(.99307 -.11754 0 1 36.748 222.061)"
          fill="#C0C8FD"
          r={12.625}
        />
        <circle
          transform="matrix(.99307 -.11754 0 1 20.628 252.825)"
          fill="#C0C8FD"
          r={1.804}
        />
        <rect
          width={28.857}
          height={3.607}
          rx={1.804}
          transform="matrix(.99307 -.11754 0 1 26.002 250.386)"
          fill="#C0C8FD"
        />
        <circle
          transform="matrix(.99307 -.11754 0 1 20.628 265.45)"
          fill="#C0C8FD"
          r={1.804}
        />
        <rect
          width={28.857}
          height={3.607}
          rx={1.804}
          transform="matrix(.99307 -.11754 0 1 26.002 263.01)"
          fill="#C0C8FD"
        />
        <circle
          transform="matrix(.99307 -.11754 0 1 20.628 278.075)"
          fill="#C0C8FD"
          r={1.804}
        />
        <rect
          width={28.857}
          height={3.607}
          rx={1.804}
          transform="matrix(.99307 -.11754 0 1 26.002 275.635)"
          fill="#C0C8FD"
        />
        <rect
          x={0.248}
          y={0.221}
          width={54.508}
          height={35.571}
          rx={2.75}
          transform="matrix(.99307 -.11754 0 1 72.57 212.44)"
          fill="#C0C8FD"
          stroke="#929FFB"
          strokeWidth={0.5}
          strokeDasharray="3 3"
        />
        <rect
          width={55.008}
          height={36.071}
          rx={3}
          transform="matrix(.99307 -.11754 0 1 68.986 209.228)"
          fill="#929FFB"
        />
        <rect
          width={55.008}
          height={36.071}
          rx={3}
          transform="matrix(.99307 -.11754 0 1 67.195 207.636)"
          fill="#fff"
        />
        <rect
          x={0.248}
          y={0.221}
          width={54.508}
          height={35.571}
          rx={2.75}
          transform="matrix(.99307 -.11754 0 1 137.047 204.809)"
          fill="#C0C8FD"
          stroke="#929FFB"
          strokeWidth={0.5}
          strokeDasharray="3 3"
        />
        <rect
          width={55.008}
          height={36.071}
          rx={3}
          transform="matrix(.99307 -.11754 0 1 137.046 249.868)"
          fill="#A6B1FF"
        />
        <rect
          width={42.383}
          height={1.804}
          rx={0.902}
          transform="matrix(.99307 -.11754 0 1 74.36 213.101)"
          fill="#929FFB"
        />
        <rect
          width={42.383}
          height={1.804}
          rx={0.902}
          transform="matrix(.99307 -.11754 0 1 74.36 220.315)"
          fill="#929FFB"
        />
        <rect
          width={42.383}
          height={1.804}
          rx={0.902}
          transform="matrix(.99307 -.11754 0 1 74.36 227.529)"
          fill="#929FFB"
        />
        <rect
          width={24.348}
          height={1.804}
          rx={0.902}
          transform="matrix(.99307 -.11754 0 1 74.36 234.743)"
          fill="#929FFB"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M163.911 255.255c-5.193.614-9.403 5.352-9.403 10.581 0 5.23 4.21 8.971 9.403 8.356 5.193-.615 9.403-5.352 9.403-10.582 0-5.229-4.21-8.97-9.403-8.355zm-12.537 10.952c0-6.972 5.613-13.289 12.537-14.108 6.924-.82 12.538 4.168 12.538 11.14 0 6.973-5.614 13.29-12.538 14.109-6.924.82-12.537-4.168-12.537-11.141z"
          fill="#929FFB"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M163.911 255.255c-5.193.614-9.403 5.352-9.403 10.581 0 5.23 4.21 8.971 9.403 8.356 5.193-.615 9.403-5.352 9.403-10.582 0-.871.702-1.661 1.567-1.763.866-.103 1.568.521 1.568 1.392 0 6.973-5.614 13.29-12.538 14.109-6.924.82-12.537-4.168-12.537-11.141 0-6.972 5.613-13.289 12.537-14.108.866-.103 1.567.521 1.567 1.392 0 .872-.701 1.661-1.567 1.764z"
          fill="#fff"
        />
        <rect
          width={24.348}
          height={14.428}
          rx={3}
          transform="matrix(.99307 -.11754 0 1 103.912 253.79)"
          fill="#A6B1FF"
        />
        <rect
          width={24.348}
          height={14.428}
          rx={3}
          transform="matrix(.99307 -.11754 0 1 103.912 275.432)"
          fill="#A6B1FF"
        />
        <rect
          width={24.348}
          height={14.428}
          rx={3}
          transform="matrix(.99307 -.11754 0 1 72.568 257.5)"
          fill="#A6B1FF"
        />
        <rect
          width={24.348}
          height={14.428}
          rx={3}
          transform="matrix(.99307 -.11754 0 1 72.568 279.142)"
          fill="#A6B1FF"
        />
      </g>
      {/* Screen 1 End */}
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M77.004 311.333c.48 0 .966-.116 1.418-.358l8.997-4.829a3.002 3.002 0 10-2.84-5.292l-8.997 4.83a3.002 3.002 0 001.422 5.649z"
        fill="#FEC1B5"
      />
      <path
        d="M98.317 365.725c2.032.33 2.899.382 2.899.382l1.471-.382s1.521 1.186.848 2.121c.503-.009 1.102.984.421 2.346-.586 1.173-2.84.746-3.759.553-1.673-.149-4.495-.346-5.939-.58-3.05-.495-3.237-1.335-2.556-3.377.675-2.027 4.524-1.402 6.569-1.07l.046.007z"
        fill="#151F4D"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M101.706 368.977c1.932-.348 2.425-.222 2.077-2.155l-1.755-23.673 1.201-20.887c.155-1.958-2.979 1.413-4.936 1.258-1.942-.151-4.518-1.231-4.672.726l-.804 20.392 5.154 23.447c.31 1.719 1.412.949 3.1.949.209 0 .421-.018.635-.057z"
        fill="#151F4D"
      />
      <path
        d="M86.124 371.554H92.11s2.044.681 1.364 3.404c0 .227-.273.817-1.362 1.361l-9.615.681c-3.628 0-3.627-1.815-3.023-3.631.605-1.815 4.232-1.815 6.65-1.815z"
        fill="#151F4D"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M90.786 374.457c1.959-.135 3.042.143 2.907-1.816l-.914-29.207 3.95-17.85c0-1.963-6.726-1.185-8.69-1.185-1.963 0-4.896 1.55-4.896 3.514l.705 17.122 3.541 28.095c.13 1.876 1.297 1.336 3.148 1.336.083 0 .165-.003.249-.009z"
        fill="#151F4D"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M102.426 303.074l-9.389 3.286c-1.287-.177-2.509-3.491-2.618-4.786a2.772 2.772 0 013.134-3.001l5.885.814-1.864-11.667c-.235-1.473.748-3.016 2.216-3.282a2.763 2.763 0 013.225 2.284l1.936 12.118a3.842 3.842 0 01-2.525 4.234z"
        fill="#293780"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M87.927 287.312l6.717-3.878c3.929-2.268 8.84.567 8.84 5.104l-1.9 16.05c-.386 2.55.557 7.409.557 7.409.446.964.843 2.992.843 4.054 0 0 1.283 2.546.51 5.804-.324 1.364-11.405 8.321-20.864 7.205l-.296-4.333c.05-1.464.187-2.924.413-4.372l.575-3.679c.192-1.241.246-2.5.161-3.753l-.996-14.656a11.053 11.053 0 015.44-10.955z"
        fill="#293780"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M94.918 287.8h-6.286v-12.513h6.286V287.8z"
        fill="#FEC1B5"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M88.632 286.64s6.178 1.795 6.286-3.382c0 0 .569-.494 1.809-.321 1.239.172 2.145 7.231-10.728 5.586l2.633-1.883z"
        fill="#293780"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M85.011 274.569v7.156a2.195 2.195 0 002.703 2.135l3.763-.896a3.418 3.418 0 002.441-2.214l1.995-5.793a.873.873 0 00-.151-.837 6.43 6.43 0 00-6.937-2.036l-2.617.843a1.724 1.724 0 00-1.197 1.642z"
        fill="#FEC1B5"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M96.924 274.086a1.977 1.977 0 01-.056-.221c-.115-.591.093-1.199.06-1.8-.083-1.477-1.78-2.783-3.167-2.886-.892-.066-1.781.118-2.674.168-.893.05-1.852-.059-2.547-.622-.587-.475-.917-1.221-1.533-1.658-1.073-.762-2.72-.234-3.405.89-1.209 1.98-.084 4.928 1.897 5.876.445.213.925.379 1.291.71.736.664.657 1.549.787 2.437.065.445-.04 1.746.238 2.007.262.247.725.053.898-.263.358-.654 0-1.502.725-1.947.42-.258 1.373-.306 1.65.166.112.19.13.418.143.638.108 1.801-.384 3.206.609 4.849.19.315.413.628.735.807.526.293 1.188.163 1.74-.077 1.172-.51 2.904-1.852 2.569-3.269-.109-.462-.302-.912-.293-1.386.009-.508.248-.978.44-1.447.254-.622.433-1.311.27-1.963-.088-.348-.27-.667-.377-1.009z"
        fill="#151F4C"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M88.632 283.405l2.02-.506-2.02 2.986v-2.48z"
        fill="#E8988C"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M84.319 278.51c.23 0 .432-.047.692-.197v-.249c-.38.219-.787.295-1.048.144-.261-.15-.406-.476-.406-.915 0-.959.624-2.098 1.454-2.577v-.264c-.888.513-1.664 1.815-1.664 2.841 0 .518.182.908.51 1.098a.918.918 0 00.462.119z"
        fill="#191C3C"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M90.368 279.132a.81.81 0 00.81-.811c0-.458-.293-.86-.727-1.002l-6.324-2.063-.066.201 6.324 2.062a.842.842 0 01.582.802.6.6 0 01-.714.589l-.04.207c.052.01.103.015.155.015z"
        fill="#191C3C"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M76.999 311.333a2.999 2.999 0 002.56-1.431l8.997-14.631a3.003 3.003 0 00-5.115-3.146l-8.997 14.632a3.003 3.003 0 002.555 4.576z"
        fill="#FEC1B5"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M80.918 305.134c1.068 0 2.111-.538 2.712-1.514l5.077-8.257a3.18 3.18 0 00-5.417-3.331l-5.077 8.257a3.18 3.18 0 002.705 4.845z"
        fill="#293780"
      />
      <path
        d="M348.945 369.55a8.918 8.918 0 00-.945-.846c.374-.897 1.2-2.455 1.794-3.169 2.124-2.578 3.169 1.954 3.792 3.006.533.897 1.231 1.692 1.831 2.544.748 1.065 3.04 3.088.318 3.225-3.136.158-4.862-2.8-6.79-4.76zM366.587 371.72a8.761 8.761 0 00-1.263-.128c-.228-.947-.478-2.691-.424-3.612.196-3.332 3.717-.283 4.839.201.959.413 1.992.641 2.979.979 1.233.421 4.279.707 2.16 2.419-2.437 1.968-5.576.592-8.291.141z"
        fill="#000"
      />
      <path
        d="M366.651 311.85l2.428 1.29s1.094 15.142 1.228 19.119c.133 3.978-1.169 35.647-1.169 35.647h-4.243s-1.563-24.325-4.017-35.496c-2.453-11.17 5.773-20.56 5.773-20.56z"
        fill="#000"
      />
      <path
        d="M353.334 327.006c3.175 11.254 2.316 23.963-3.739 38.106-.958 2.238 4.973 2.618 4.973 2.618s5.62-8.641 9.732-25.146c1.539-6.187 1.026-5.416 1.736-9.91.506-3.179 1.123-8.118 1.197-11.351.064-2.76-.239-5.113-.437-7.854-.256-3.652-2.846-3.726-5.335-3.889-1.747-.115-4.465-1.134-5.852.531-.909 1.092-1.693 3.46-2.275 4.863-1.94 4.664-1.052 8.297 0 12.032z"
        fill="#000"
      />
      <path
        d="M363.082 286.28c.761.131 1.668-.065 2.451-.103.037-.789.332-2.495-.159-3.153-.631-.849-1.996-.685-2.373.208-.122.273-.178 3.004.081 3.048zM387.268 286.839c1.139-2.166 1.094-4.452 1.923-6.817.322-.919.641-1.47 1.631-1.545 1.888-.143 4.91 2.874 3.813 5.246-.441.952-1.697 1.222-2.385 1.709-.746.525-1.098 1.11-1.508 2.059-.868 2.012-1.606 4.021-2.62 5.919-1.026 1.922-1.923 4.02-2.894 5.902-.989.105-6.216-1.083-6.666-2.382-.499-1.41 3.281-1.678 3.926-2.476 1.805-2.249 3.392-4.975 4.78-7.615z"
        fill="#E58F77"
      />
      <path
        d="M366.614 272.414c-2.393-2.786-7.442-1.429-8.48 1.558-.995 2.859 1.967 6.997 4.732 8.909 8.187 5.659 8.849-9.838 1.774-10.986"
        fill="#E58F77"
      />
      <path
        d="M364.256 285.416c-.304.243-.667.456-1.022.71-1.633 1.177-2.342 2.964-4.388 3.688a6.692 6.692 0 01-3.018.316 4.91 4.91 0 01-1.557-.456c-.3-.15-1.06-.98-1.296-.998 2.688.209 3.328-2.891 3.49-4.857.122-1.477 0-3.048-.071-4.525-.163-3.208.86-5.79 3.434-7.859 1.076-.864 2.726-1.109 4.078-1.089 1.335.014 2.669.764 3.835 1.331.676.328 2.25 1.298 2.873.422-1.108 1.562-4.106 2.178-5.737 3.038-.926.488-1.101.671-1.218 1.586-.077.608-.377 2.18-.138 2.732-.409-.933-1.668-.709-1.698.309-.038 1.26 1.315.975 1.988 1.514.508.408 1.184 2.032 1.196 2.679.008.693-.31 1.107-.751 1.459z"
        fill="#000"
      />
      <path
        d="M369.646 302.683c-2.162 2.255-2.299 6.506-.945 9.226.858 1.724 1.154 2.041-1.13 2.307-1.826.217-3.684-.117-5.512-.205-2.201-.107-4.467-.714-6.631-1.106-1.071-.194-1.995 0-2.094-1.181-.098-1.18 1.137-2.21 1.653-3.152.692-1.266 1.132-2.75 1.676-4.085.383-.958.694-1.943.93-2.947.193-.801.463-1.703.463-2.532 0-.927-.056-1.896-.129-2.819-.116-1.377.138-2.721.345-4.074.177-1.162.598-2.257 1.01-3.353.249-.659 1.607-4.073 1.601-4.072a16.667 16.667 0 013.32.33c.534.128.934.114 1.418.359.363.183.756.443 1.165.448-.037.267-.512 1.261-.55 1.538-.069.487 1.311 2.306 1.525 2.996.256.875.574 1.731.949 2.563.789 1.673 2.212 3.379 2.692 5.139.491 1.79-.574 3.385-1.756 4.62z"
        fill="#526DFD"
      />
      <path
        d="M375.513 298.473c-1.066-.399-1.933-.759-2.188-.834-1.17-.338-2.28-.865-3.436-1.294-2.131-.791-4.374-1.367-6.357-2.506-2.065-1.187-3.769-3.546-1.735-5.797 1.862-2.061 6.884 1.184 8.659 2.128 2.149 1.143 4.293 2.178 6.703 2.587 2.068.345 4.005.055 6.052.154-.196 2.146-1.479 4.357-2.159 6.42-.315.957-3.349-.036-5.539-.858z"
        fill="#526DFD"
      />
      <path
        d="M212.571 385.965v-20.953L120 354.471v20.953l92.571 10.541z"
        fill="url(#hero-1-svg-prefix__paint3_linear)"
      />
      <path
        d="M212.572 365.011L336 350.958v20.953l-123.428 14.053v-20.953z"
        fill="url(#hero-1-svg-prefix__paint4_linear)"
      />
      <path
        d="M212.571 365.964v-3.619L120 351.804v3.62l92.571 10.54z"
        fill="url(#hero-1-svg-prefix__paint5_linear)"
      />
      <path
        d="M212.572 362.344L336 348.291v3.619l-123.428 14.054v-3.62z"
        fill="url(#hero-1-svg-prefix__paint6_linear)"
      />
      <path
        d="M212.571 363.358v-20.953L120 331.865v20.953l92.571 10.54z"
        fill="url(#hero-1-svg-prefix__paint7_linear)"
      />
      <path
        d="M212.572 342.405L336 328.351v20.953l-123.428 14.054v-20.953z"
        fill="url(#hero-1-svg-prefix__paint8_linear)"
      />
      <path
        d="M212.571 343.358v-3.619L120 329.198v3.62l92.571 10.54z"
        fill="url(#hero-1-svg-prefix__paint9_linear)"
      />
      <path
        d="M212.572 339.738L336 325.685v3.619l-123.428 14.054v-3.62z"
        fill="url(#hero-1-svg-prefix__paint10_linear)"
      />
      <path
        d="M212.571 340.692v-20.953L120 309.198v20.953l92.571 10.541z"
        fill="url(#hero-1-svg-prefix__paint11_linear)"
      />
      <path
        d="M212.572 319.738L336 305.685v20.953l-123.428 14.053v-20.953z"
        fill="url(#hero-1-svg-prefix__paint12_linear)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M120.161 309.217l92.41 10.522 123.428-14.054-94.212-10.727-121.626 14.259z"
        fill="#C0C8FD"
      />
      <rect
        width={113.333}
        height={6.667}
        rx={3.333}
        transform="matrix(.99307 -.11754 0 1 218.667 326.958)"
        fill="url(#hero-1-svg-prefix__paint13_linear)"
      />
      <rect
        width={113.333}
        height={6.667}
        rx={3.333}
        transform="matrix(.99307 -.11754 0 1 218.667 372.278)"
        fill="url(#hero-1-svg-prefix__paint14_linear)"
      />
      <rect
        width={81.333}
        height={6.667}
        rx={3.333}
        transform="matrix(.99307 .11754 0 1 125.334 317.624)"
        fill="url(#hero-1-svg-prefix__paint15_linear)"
      />
      <rect
        width={81.333}
        height={6.667}
        rx={3.333}
        transform="matrix(.99307 .11754 0 1 125.334 362.958)"
        fill="url(#hero-1-svg-prefix__paint16_linear)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M157.333 308.146a1.484 1.484 0 011.328-1.466l78.699-9.045c.115-.014.23-.014.345-.001l60.962 6.805a1.485 1.485 0 011.333 1.464c0 .749-.574 1.379-1.333 1.464l-79.865 8.915a1.61 1.61 0 01-.34 0l-59.796-6.675a1.485 1.485 0 01-1.333-1.461z"
        fill="#A6B0F8"
      />
      <path
        d="M219.809 313.923v-47.337l-53.143-6.051v47.337l53.143 6.051z"
        fill="url(#hero-1-svg-prefix__paint17_linear)"
      />
      <path
        d="M219.808 266.586l70.857-8.068v47.337l-70.857 8.068v-47.337z"
        fill="url(#hero-1-svg-prefix__paint18_linear)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M166.757 260.477l53.051 6.04 70.857-8.068-54.085-6.158-69.823 8.186z"
        fill="#CEFFF4"
      />
      <path
        d="M288.558 342.058a11.607 11.607 0 01-10.481 3.637l.529-2.944a9.682 9.682 0 01-.924-.201 8.546 8.546 0 01-6.247-8.271 8.544 8.544 0 008.551 8.547 8.554 8.554 0 008.378-6.826c-.037.201-.089.41-.149.611a8.394 8.394 0 01-1.886 3.42l2.229 2.027z"
        fill="#FFB0D0"
      />
      <path
        d="M291.504 333.049l-3.012.283a8.533 8.533 0 00-4.696-6.714l1.364-2.698a11.575 11.575 0 016.344 9.129z"
        fill="#09089F"
      />
      <path
        d="M260.976 292.191l2.006.275 1.312-1.49-2.803-2.057-.515 3.272zM279.45 369.488l-.597 12.079-5.046.082-1.208-11.758 6.851-.403z"
        fill="#FFBA94"
      />
      <path
        d="M279.658 380.905l.559 8.845-4.533.164-.179-.85-1.386 1.066-9.154-.03s-.231-1.602.581-1.989c.813-.388 2.818-1.17 2.818-1.17l4.227-3.093.283-3.778 6.784.835z"
        fill="#000"
      />
      <path
        d="M301.314 365.308l5.784 10.618-4.271 2.698-7.156-9.404 5.643-3.912z"
        fill="#FFBA94"
      />
      <path
        d="M307.044 373.93l5.956 6.572-3.444 2.951-.671-.552-.418 1.699-7.171 5.679s-1.177-1.111-.782-1.916c.395-.804 1.476-2.675 1.476-2.675l1.379-5.052-2.132-3.13 5.807-3.576z"
        fill="#000"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M305.957 370.36l-4.249 2.236-2.848 1.497s-15.699-13.636-16.631-29.889c-.931-16.252-1.118-17.556-1.118-17.556l5.509.551 7.566.76 2.617 20.918s1.498.931.932 1.87l8.222 19.613z"
        fill="#000"
      />
      <path
        d="M298.294 373.401l6.984-4.412a.711.711 0 01.977.217l.827 1.311a.708.708 0 01-.216.976l-6.985 4.412a.71.71 0 01-.976-.217l-.828-1.311a.708.708 0 01.217-.976z"
        fill="#000"
      />
      <path
        d="M285.606 282.541s1.908-.44 2.027 1.438c.12 1.878-2.638 3.778-2.638 3.778l-.582-.574 1.193-4.642z"
        fill="#0E1134"
      />
      <path
        d="M274.664 290.804c1.014 2.787 6.441 3.883 8.997-2.108 2.237-5.246.328-8.704-2.624-8.585-2.952.12-4.741 1.379-4.741 1.379s-.551 1.453-1.021 3.204c-.238.894-.641 2.251-.745 3.145-.12 1.043-.298 1.796.134 2.965z"
        fill="#FFBA94"
      />
      <path
        d="M284.414 287.183s.53-3.047.127-2.988c-.403.06-8.245-.559-7.969-3.077.276-2.519 2.922-1.93 2.922-1.93s9.579.7 4.92 7.995z"
        fill="#0E1134"
      />
      <path
        d="M286.247 282.95s1.043-2.876-1.886-3.748c-2.93-.872-2.319-.633-3.534-2.101-1.215-1.468-3.846-.902-4.271.648-.425 1.55.678 2.862.678 2.862l9.013 2.339z"
        fill="#000"
      />
      <path
        d="M277.854 279.255s-2.453-1.319-2.736.447c-.38 2.362 3.31 3.361 3.31 3.361l-.574-3.808z"
        fill="#000"
      />
      <path
        d="M277.184 292.467l.186 4.113h5.606l-.664-5.186-5.128 1.073z"
        fill="#FFBA94"
      />
      <path
        d="M279.242 281.445s-1.312 1.871-1.499 4.486c0 0 .373-.186.373.373 0 .559-.745 4.299-.186 5.417.559 1.118 4.293.745 4.86.373.566-.373 4.107-4.3 4.107-5.231 0-.932-.745-1.685-.745-1.685s-1.305-5.417-3.921-6.535c-2.617-1.117-2.989 2.802-2.989 2.802z"
        fill="#000"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M286.903 330.762c-.745 8.964-6.91 45.202-6.91 45.202h-7.849c-3.362-27.273-1.499-44.085-1.499-44.085l2.222-.149 14.036-.968z"
        fill="#000"
      />
      <path
        d="M272.337 374.093h7.268c.522 0 .947.425.947.947v.909a.948.948 0 01-.947.946h-7.268a.948.948 0 01-.947-.946v-.909c0-.522.425-.947.947-.947z"
        fill="#000"
      />
      <path
        d="M286.338 296.953h2.236s5.576 2.608 9.109 12.638c3.534 10.029-1.856 10.827-3.533 9.828-1.677-.998-7.812-22.466-7.812-22.466z"
        fill="#5367CD"
      />
      <path
        d="M262.796 295.269l.186-2.802-2.802-.559-.746 2.616 3.362.745z"
        fill="#FFBA94"
      />
      <path
        d="M260.924 292.839l-.745-.931s-.157-2.582-.187-4.486 1.748-4.867 2.803-4.733c1.055.135 3.093 3.421 2.797 5.792-.296 2.37-1.954 2.548-2.797 3.852-.842 1.304-1.871.506-1.871.506z"
        fill="#FFBA94"
      />
      <path
        d="M295.088 330.664c-10.19 5.738-24.443 1.215-24.443 1.215.746-9.71-.559-22.415-.373-22.601.023-.023-.156-.008-.499.029-2.333.254-12.113 1.573-13.47-1.222l-.045-.111c-1.312-3.361 2.803-14.569 2.803-14.569l4.48 1.118.186 4.486 6.165-.745c1.118-.932 5.979-2.243 5.979-2.243 2.989-3.92 7.104-2.988 7.104-2.988l1.118 2.243c1.297.32 3.168 1.088 4.51 1.676a5.071 5.071 0 012.586 2.534c4.592 10.075 3.899 31.178 3.899 31.178z"
        fill="#5367CD"
      />
      <path
        d="M284.099 295.269s-3.361-.373-7.29.931c0 0 5.777-.693 9.616-.156 0 0-1.766-.775-2.326-.775z"
        fill="#3E53BB"
      />
      <path
        d="M277.846 285.283l.179.06c.06.015.097.067.112.127.104.506.462 2.906-1.64 3.442a.162.162 0 01-.119-.015l-.045-.022a.164.164 0 01-.075-.224c.015-.022.03-.044.06-.067.537-.357 1.707-1.371 1.319-3.107a.17.17 0 01.127-.201.105.105 0 01.082.007z"
        fill="#FFBA94"
      />
      <path
        d="M270.08 304.553v4.628l-.306.126c-2.333.254-12.113 1.573-13.47-1.222.022-.067.045-.126.067-.193 0-.008 2.221 2.958 13.709-3.339z"
        fill="#3E53BB"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M37.953 374.865c1.489-5.118 3.22-11.195 3.518-13.114H24.2c.297 1.919 2.03 7.996 3.518 13.114.32.883 2.292 1.599 5.118 1.599 2.827 0 4.798-.716 5.118-1.599z"
        fill="#E5E8FE"
      />
      <path
        d="M41.472 361.751c0 .706-3.867 1.279-8.637 1.279-4.77 0-8.636-.573-8.636-1.279 0-.707 3.867-1.28 8.636-1.28 4.77 0 8.637.573 8.637 1.28z"
        fill="#515A9A"
      />
      <path
        d="M31.178 356.804h3.132v5.118c-.395.148-.776.212-1.566.212s-1.185-.064-1.566-.212c.014-.149 0-5.118 0-5.118z"
        fill="#9E798F"
      />
      <ellipse
        cx={29.665}
        cy={352.743}
        rx={8.665}
        ry={8.743}
        fill="url(#hero-1-svg-prefix__paint19_linear)"
      />
      <ellipse
        cx={37.907}
        cy={354.738}
        rx={6.763}
        ry={6.824}
        fill="url(#hero-1-svg-prefix__paint20_linear)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M421.953 374.865c1.489-5.118 3.221-11.195 3.518-13.114h-17.272c.298 1.919 2.029 7.996 3.518 13.114.32.883 2.292 1.599 5.118 1.599 2.827 0 4.798-.716 5.118-1.599z"
        fill="#E5E8FE"
      />
      <path
        d="M425.472 361.751c0 .706-3.867 1.279-8.637 1.279-4.769 0-8.636-.573-8.636-1.279 0-.707 3.867-1.28 8.636-1.28 4.77 0 8.637.573 8.637 1.28z"
        fill="#515A9A"
      />
      <path
        d="M415.178 356.804h3.132v5.118c-.395.148-.776.212-1.566.212s-1.185-.064-1.566-.212c.014-.149 0-5.118 0-5.118z"
        fill="#9E798F"
      />
      <ellipse
        cx={413.665}
        cy={352.743}
        rx={8.665}
        ry={8.743}
        fill="url(#hero-1-svg-prefix__paint21_linear)"
      />
      <ellipse
        cx={421.907}
        cy={354.738}
        rx={6.763}
        ry={6.824}
        fill="url(#hero-1-svg-prefix__paint22_linear)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M229.953 396.865c1.489-5.118 3.221-11.195 3.518-13.114h-17.272c.298 1.919 2.029 7.996 3.518 13.114.32.883 2.292 1.599 5.118 1.599 2.827 0 4.798-.716 5.118-1.599z"
        fill="#E5E8FE"
      />
      <path
        d="M233.472 383.751c0 .706-3.867 1.279-8.637 1.279-4.769 0-8.636-.573-8.636-1.279 0-.707 3.867-1.28 8.636-1.28 4.77 0 8.637.573 8.637 1.28z"
        fill="#515A9A"
      />
      <path
        d="M223.178 378.804h3.132v5.118c-.395.148-.776.212-1.566.212s-1.185-.064-1.566-.212c.014-.149 0-5.118 0-5.118z"
        fill="#9E798F"
      />
      <ellipse
        cx={221.665}
        cy={374.743}
        rx={8.665}
        ry={8.743}
        fill="url(#hero-1-svg-prefix__paint23_linear)"
      />
      <ellipse
        cx={229.907}
        cy={376.738}
        rx={6.763}
        ry={6.824}
        fill="url(#hero-1-svg-prefix__paint24_linear)"
      />
      <g opacity={0.8}>
        <rect
          width={48}
          height={48}
          rx={3}
          transform="matrix(.99307 .11754 0 1 313 168)"
          fill="url(#hero-1-svg-prefix__paint25_linear)"
        />
        <path
          d="M331.127 192.691l-6.379 1.682v.04l6.379 3.192v2.673l-10.182-5.097v-2.476l10.182-2.687v2.673zM338.693 187.041l2.294.96-6.037 14.597-2.274-.957 6.017-14.6zM342.541 198.956l6.379-1.682v-.04l-6.379-3.192v-2.673l10.182 5.097v2.476l-10.182 2.687v-2.673z"
          fill="#fff"
        />
      </g>
      {/* Drone 1 Start */}
      <motion.path
        style={{ x: xDrone1 }}
        d="M135.267 64.053L174 81.268V119.8l-38.733-17.215V64.053z"
        fill="url(#hero-1-svg-prefix__paint26_linear)"
      />
      <motion.path
        style={{ x: xDrone1 }}
        fillRule="evenodd"
        clipRule="evenodd"
        d="M152.923 89.372c.225-.232.607-.209.967.058l3.056 2.27.003 4.149-3.848-4.963c-.404-.521-.483-1.199-.178-1.514zm5.696 3.57l4.709 3.496.005 7.646-4.711-6.077-.003-5.066zm6.382 4.738l4.709 3.496.007 11.143-4.711-6.077-.005-8.563z"
        fill="url(#hero-1-svg-prefix__paint27_linear)"
      />
      <motion.path
        style={{ x: xDrone1 }}
        d="M173.999 81.256l38.732-17.215v38.533l-38.732 17.215V81.256z"
        fill="url(#hero-1-svg-prefix__paint28_linear)"
      />
      <motion.path
        style={{ x: xDrone1 }}
        d="M174.001 81.255l38.732-17.215-38.732-17.215-38.732 17.215 38.732 17.215z"
        fill="#A09BFF"
      />
      <motion.path
        style={{ x: xDrone1 }}
        opacity={0.6}
        fillRule="evenodd"
        clipRule="evenodd"
        d="M173.957 27.9l19.693 44.506-.914.405-18.78-42.44-18.807 42.44-.914-.405L173.957 27.9z"
        fill="#000"
      />
      <mask
        id="hero-1-svg-prefix__a"
        maskUnits="userSpaceOnUse"
        x={135}
        y={27}
        width={78}
        height={38}
      >
        <motion.path
          style={{ x: xDrone1 }}
          d="M174 27.401l38.733 36.64L174 46.824 135.268 64.04 174 27.401z"
          fill="#C0C8FD"
        />
      </mask>
      <g mask="url(#hero-1-svg-prefix__a)">
        <motion.path
          style={{ x: xDrone1 }}
          opacity={0.6}
          fillRule="evenodd"
          clipRule="evenodd"
          d="M173.955 28.242l18.033 26.744-.829.559-17.204-25.516-17.231 25.516-.828-.56 18.059-26.743z"
          fill="#000"
        />
      </g>
      <motion.path
        style={{ x: xDrone1 }}
        fillRule="evenodd"
        clipRule="evenodd"
        d="M179.781 9.681h-11.562l3.469 24.317V34c0 1.256 1.035 2.273 2.312 2.273s2.312-1.017 2.312-2.272l3.469-24.32z"
        fill="#4657CD"
      />
      <motion.path
        style={{ x: xDrone1 }}
        fillRule="evenodd"
        clipRule="evenodd"
        d="M207.004 30.108c-7.842.731-16.279-.356-22.353-3.263-6.075-2.906-9.479-6.943-7.951-10.695l-2.677-1.822-2.678 1.822c1.528 3.752-1.876 7.789-7.951 10.695-6.074 2.907-14.511 3.994-22.353 3.263l-3.808 1.822 3.808 1.822c7.842-.73 16.279.357 22.353 3.264 6.075 2.906 8.348 6.943 6.821 10.695l3.808 1.822 3.808-1.822c-1.528-3.752.745-7.789 6.82-10.695 6.074-2.907 14.511-3.995 22.353-3.264l3.808-1.822-3.808-1.822z"
        fill="#B5BAEF"
      />
      <motion.path
        style={{ x: xDrone1 }}
        fillRule="evenodd"
        clipRule="evenodd"
        d="M177.817 13.418c-1.018.429-2.354.689-3.816.689-1.437 0-2.752-.25-3.763-.666 1.482 3.736-.799 7.747-6.843 10.638-6.074 2.907-14.511 3.995-22.353 3.264l-3.808 1.822 3.808 1.822c7.842-.731 16.279.356 22.353 3.263 6.075 2.906 8.348 6.943 6.82 10.695l3.808 1.822 3.808-1.822c-1.528-3.752.746-7.789 6.82-10.695 6.075-2.907 14.512-3.994 22.354-3.263l3.808-1.822-3.808-1.822c-7.842.73-16.279-.357-22.354-3.264-6.056-2.897-8.334-6.918-6.834-10.66z"
        fill="url(#hero-1-svg-prefix__paint29_linear)"
      />
      <motion.ellipse
        style={{ x: xDrone1 }}
        opacity={0.6}
        cx={174}
        cy={9.681}
        rx={20.233}
        ry={9.681}
        fill="url(#hero-1-svg-prefix__paint30_linear)"
      />
      <motion.path
        style={{ x: xDrone1 }}
        fillRule="evenodd"
        clipRule="evenodd"
        d="M168.22 9.652a5.781 5.781 0 0111.561 0v.059c-.035 1.514-2.61 2.736-5.781 2.736s-5.746-1.222-5.78-2.735l-.001-.021v-.01l.001-.029z"
        fill="url(#hero-1-svg-prefix__paint31_linear)"
      />
      <motion.path
        style={{ x: xDrone1 }}
        fillRule="evenodd"
        clipRule="evenodd"
        d="M179.781 44.864h-11.562l3.468 25.374v.003c0 1.31 1.036 2.372 2.313 2.372 1.277 0 2.312-1.062 2.312-2.372l3.469-25.377z"
        fill="#4657CD"
      />
      <motion.ellipse
        style={{ x: xDrone1 }}
        opacity={0.6}
        cx={174}
        cy={44.865}
        rx={20.233}
        ry={9.681}
        fill="url(#hero-1-svg-prefix__paint32_linear)"
      />
      <motion.path
        style={{ x: xDrone1 }}
        fillRule="evenodd"
        clipRule="evenodd"
        d="M168.219 44.86a5.78 5.78 0 1111.562 0v.005c0 1.527-2.588 2.766-5.781 2.766-3.193 0-5.781-1.239-5.781-2.766v-.005z"
        fill="url(#hero-1-svg-prefix__paint33_linear)"
      />
      <motion.path
        style={{ x: xDrone1 }}
        fillRule="evenodd"
        clipRule="evenodd"
        d="M216.548 27.273h-11.562l3.468 24.317v.002c0 1.255 1.035 2.273 2.312 2.273 1.278 0 2.313-1.018 2.313-2.273l3.469-24.32z"
        fill="#4657CD"
      />
      <motion.ellipse
        style={{ x: xDrone1 }}
        opacity={0.6}
        cx={210.766}
        cy={27.272}
        rx={20.233}
        ry={9.681}
        fill="url(#hero-1-svg-prefix__paint34_linear)"
      />
      <motion.path
        style={{ x: xDrone1 }}
        fillRule="evenodd"
        clipRule="evenodd"
        d="M204.986 27.26a5.781 5.781 0 0111.562 0v.026c-.015 1.521-2.598 2.752-5.781 2.752-3.184 0-5.766-1.23-5.781-2.752v-.008-.006-.012z"
        fill="url(#hero-1-svg-prefix__paint35_linear)"
      />
      <motion.path
        style={{ x: xDrone1 }}
        fillRule="evenodd"
        clipRule="evenodd"
        d="M143.015 27.273h-11.562l3.468 24.319c0 1.255 1.035 2.273 2.313 2.273 1.277 0 2.312-1.018 2.312-2.273l3.469-24.32z"
        fill="#4657CD"
      />
      <motion.ellipse
        style={{ x: xDrone1 }}
        opacity={0.6}
        cx={137.233}
        cy={27.272}
        rx={20.233}
        ry={9.681}
        fill="url(#hero-1-svg-prefix__paint36_linear)"
      />
      <motion.path
        style={{ x: xDrone1 }}
        fillRule="evenodd"
        clipRule="evenodd"
        d="M131.453 27.26a5.78 5.78 0 0111.561 0l.001.012-.001.007.001.007c-.016 1.521-2.598 2.752-5.781 2.752-3.184 0-5.766-1.23-5.781-2.752v-.008-.006-.012z"
        fill="url(#hero-1-svg-prefix__paint37_linear)"
      />
      {/* Drone 1 End */}
      {/* Drone 2 Start */}
      <motion.path
        style={{ y: yDrone2 }}
        d="M246.557 204.99l13.902 6.179V225l-13.902-6.179V204.99z"
        fill="url(#hero-1-svg-prefix__paint38_linear)"
      />
      <motion.path
        style={{ y: yDrone2 }}
        fillRule="evenodd"
        clipRule="evenodd"
        d="M252.894 214.078c.081-.083.218-.075.347.021l1.097.815.001 1.489-1.381-1.781c-.145-.187-.173-.431-.064-.544zm2.045 1.282l1.69 1.254.002 2.745-1.691-2.181-.001-1.818zm2.29 1.7l1.691 1.255.002 4-1.691-2.181-.002-3.074z"
        fill="url(#hero-1-svg-prefix__paint39_linear)"
      />
      <motion.path
        style={{ y: yDrone2 }}
        d="M260.459 211.165l13.902-6.179v13.831l-13.902 6.179v-13.831z"
        fill="url(#hero-1-svg-prefix__paint40_linear)"
      />
      <motion.path
        style={{ y: yDrone2 }}
        d="M260.461 211.165l13.902-6.179-13.902-6.179-13.903 6.179 13.903 6.179z"
        fill="#A09BFF"
      />
      <motion.path
        style={{ y: yDrone2 }}
        opacity={0.6}
        fillRule="evenodd"
        clipRule="evenodd"
        d="M260.443 192.015l7.069 15.974-.328.145-6.741-15.233-6.75 15.233-.328-.145 7.078-15.974z"
        fill="#000"
      />
      <mask
        id="hero-1-svg-prefix__b"
        maskUnits="userSpaceOnUse"
        x={246}
        y={191}
        width={29}
        height={14}
      >
        <motion.path
          style={{ y: yDrone2 }}
          d="M260.459 191.835l13.902 13.151-13.902-6.179-13.902 6.179 13.902-13.151z"
          fill="#C0C8FD"
        />
      </mask>
      <g mask="url(#hero-1-svg-prefix__b)">
        <motion.path
          style={{ y: yDrone2 }}
          opacity={0.6}
          fillRule="evenodd"
          clipRule="evenodd"
          d="M260.443 192.137l6.473 9.599-.298.201-6.175-9.159-6.185 9.159-.297-.201 6.482-9.599z"
          fill="#000"
        />
      </g>
      <motion.path
        style={{ y: yDrone2 }}
        fillRule="evenodd"
        clipRule="evenodd"
        d="M262.534 185.475h-4.15l1.245 8.728v.001c0 .45.371.815.83.815.458 0 .83-.365.83-.815l1.245-8.729z"
        fill="#4657CD"
      />
      <motion.path
        style={{ y: yDrone2 }}
        fillRule="evenodd"
        clipRule="evenodd"
        d="M272.306 192.807c-2.815.262-5.843-.128-8.024-1.172-2.18-1.043-3.402-2.492-2.853-3.838l-.962-.654-.961.654c.549 1.346-.673 2.795-2.853 3.838-2.181 1.044-5.209 1.434-8.024 1.172l-1.367.654 1.367.654c2.815-.263 5.843.128 8.024 1.171 2.18 1.043 2.996 2.492 2.448 3.839l1.366.654 1.367-.654c-.548-1.347.268-2.796 2.448-3.839 2.181-1.043 5.209-1.434 8.024-1.171l1.366-.654-1.366-.654z"
        fill="#B5BAEF"
      />
      <motion.path
        style={{ y: yDrone2 }}
        fillRule="evenodd"
        clipRule="evenodd"
        d="M261.83 186.816c-.366.154-.845.248-1.37.248-.516 0-.988-.09-1.351-.239.532 1.341-.287 2.78-2.456 3.818-2.18 1.043-5.208 1.434-8.023 1.171l-1.367.654 1.367.654c2.815-.262 5.843.128 8.023 1.171 2.18 1.044 2.996 2.493 2.448 3.839l1.367.654 1.367-.654c-.549-1.346.267-2.795 2.448-3.839 2.18-1.043 5.208-1.433 8.023-1.171l1.367-.654-1.367-.654c-2.815.263-5.843-.128-8.023-1.171-2.174-1.04-2.992-2.483-2.453-3.827z"
        fill="url(#hero-1-svg-prefix__paint41_linear)"
      />
      <motion.ellipse
        style={{ y: yDrone2 }}
        opacity={0.6}
        cx={260.459}
        cy={185.475}
        rx={7.262}
        ry={3.475}
        fill="url(#hero-1-svg-prefix__paint42_linear)"
      />
      <motion.path
        style={{ y: yDrone2 }}
        fillRule="evenodd"
        clipRule="evenodd"
        d="M258.384 185.464a2.075 2.075 0 014.15 0v.022c-.013.543-.937.981-2.075.981-1.138 0-2.062-.438-2.075-.981v-.008-.004-.01z"
        fill="url(#hero-1-svg-prefix__paint43_linear)"
      />
      <motion.path
        style={{ y: yDrone2 }}
        fillRule="evenodd"
        clipRule="evenodd"
        d="M262.534 198.103h-4.15l1.245 9.108v.001c0 .47.371.851.83.851a.84.84 0 00.83-.851l1.245-9.109z"
        fill="#4657CD"
      />
      <motion.ellipse
        style={{ y: yDrone2 }}
        opacity={0.6}
        cx={260.459}
        cy={198.103}
        rx={7.262}
        ry={3.475}
        fill="url(#hero-1-svg-prefix__paint44_linear)"
      />
      <motion.path
        style={{ y: yDrone2 }}
        fillRule="evenodd"
        clipRule="evenodd"
        d="M258.384 198.102a2.075 2.075 0 114.15 0v.002c0 .548-.929.992-2.075.992-1.146 0-2.075-.444-2.075-.992v-.002z"
        fill="url(#hero-1-svg-prefix__paint45_linear)"
      />
      <motion.path
        style={{ y: yDrone2 }}
        fillRule="evenodd"
        clipRule="evenodd"
        d="M275.731 191.789h-4.15l1.245 8.728v.001c0 .451.372.816.83.816.458 0 .83-.365.83-.816l1.245-8.729z"
        fill="#4657CD"
      />
      <motion.ellipse
        style={{ y: yDrone2 }}
        opacity={0.6}
        cx={273.656}
        cy={191.789}
        rx={7.262}
        ry={3.475}
        fill="url(#hero-1-svg-prefix__paint46_linear)"
      />
      <motion.path
        style={{ y: yDrone2 }}
        fillRule="evenodd"
        clipRule="evenodd"
        d="M271.581 191.784a2.075 2.075 0 014.15 0v.01c-.005.546-.932.988-2.075.988-1.143 0-2.07-.442-2.075-.988v-.003-.002-.005z"
        fill="url(#hero-1-svg-prefix__paint47_linear)"
      />
      <motion.path
        style={{ y: yDrone2 }}
        fillRule="evenodd"
        clipRule="evenodd"
        d="M249.337 191.789h-4.149l1.244 8.729c0 .451.372.816.83.816.459 0 .83-.365.83-.816l1.245-8.729z"
        fill="#4657CD"
      />
      <motion.ellipse
        style={{ y: yDrone2 }}
        opacity={0.6}
        cx={247.262}
        cy={191.789}
        rx={7.262}
        ry={3.475}
        fill="url(#hero-1-svg-prefix__paint48_linear)"
      />
      <motion.path
        style={{ y: yDrone2 }}
        fillRule="evenodd"
        clipRule="evenodd"
        d="M245.188 191.784a2.074 2.074 0 014.149 0v.01c-.005.546-.932.988-2.075.988-1.142 0-2.069-.442-2.074-.988v-.005-.005z"
        fill="url(#hero-1-svg-prefix__paint49_linear)"
      />
      {/* Drone 2 End */}
      {/* Drone 3 Start */}
      <motion.path
        style={{ x: xDrone3 }}
        d="M304.454 116.15l20.046 8.91v19.942l-20.046-8.91V116.15z"
        fill="url(#hero-1-svg-prefix__paint50_linear)"
      />
      <motion.path
        style={{ x: xDrone3 }}
        fillRule="evenodd"
        clipRule="evenodd"
        d="M313.592 129.254c.116-.12.314-.108.5.03l1.582 1.175.001 2.147-1.991-2.568c-.209-.27-.25-.621-.092-.784zm2.947 1.847l2.438 1.81.002 3.957-2.438-3.145-.002-2.622zm3.303 2.453l2.438 1.809.003 5.767-2.438-3.145-.003-4.431z"
        fill="url(#hero-1-svg-prefix__paint51_linear)"
      />
      <motion.path
        style={{ x: xDrone3 }}
        d="M324.499 125.054l20.046-8.91v19.943l-20.046 8.909v-19.942z"
        fill="url(#hero-1-svg-prefix__paint52_linear)"
      />
      <motion.path
        style={{ x: xDrone3 }}
        d="M324.501 125.053l20.045-8.909-20.045-8.91-20.046 8.91 20.046 8.909z"
        fill="#A09BFF"
      />
      <motion.path
        style={{ x: xDrone3 }}
        opacity={0.6}
        fillRule="evenodd"
        clipRule="evenodd"
        d="M324.478 97.44l10.192 23.033-.474.21-9.719-21.965-9.733 21.965-.473-.21 10.207-23.033z"
        fill="#000"
      />
      <mask
        id="hero-1-svg-prefix__c"
        maskUnits="userSpaceOnUse"
        x={304}
        y={97}
        width={41}
        height={20}
      >
        <motion.path
          style={{ x: xDrone3 }}
          d="M324.5 97.182l20.046 18.962-20.046-8.91-20.046 8.91L324.5 97.182z"
          fill="#C0C8FD"
        />
      </mask>
      <g mask="url(#hero-1-svg-prefix__c)">
        <motion.path
          style={{ x: xDrone3 }}
          opacity={0.6}
          fillRule="evenodd"
          clipRule="evenodd"
          d="M324.477 97.616l9.333 13.842-.429.289-8.904-13.206-8.917 13.206-.429-.29 9.346-13.84z"
          fill="#000"
        />
      </g>
      <motion.path
        style={{ x: xDrone3 }}
        fillRule="evenodd"
        clipRule="evenodd"
        d="M327.492 88.01h-5.984l1.795 12.585v.002c0 .649.536 1.176 1.197 1.176s1.196-.527 1.197-1.176l1.795-12.587z"
        fill="#4657CD"
      />
      <motion.path
        style={{ x: xDrone3 }}
        fillRule="evenodd"
        clipRule="evenodd"
        d="M341.581 98.582c-4.058.379-8.425-.184-11.569-1.689-3.143-1.504-4.905-3.593-4.114-5.535l-1.386-.943-1.386.943c.791 1.942-.971 4.031-4.115 5.535-3.143 1.505-7.51 2.068-11.569 1.69l-1.97.942 1.97.943c4.059-.378 8.426.185 11.569 1.689 3.144 1.504 4.321 3.593 3.53 5.535l1.971.943 1.971-.943c-.791-1.942.386-4.031 3.529-5.535 3.144-1.504 7.511-2.067 11.569-1.689l1.971-.943-1.971-.943z"
        fill="#B5BAEF"
      />
      <motion.path
        style={{ x: xDrone3 }}
        fillRule="evenodd"
        clipRule="evenodd"
        d="M326.477 89.945c-.527.222-1.219.356-1.976.356-.743 0-1.424-.13-1.947-.344.767 1.933-.414 4.009-3.542 5.505-3.143 1.505-7.51 2.068-11.569 1.69l-1.97.942 1.97.943c4.059-.378 8.426.185 11.569 1.689 3.144 1.504 4.321 3.593 3.53 5.535l1.971.943 1.971-.943c-.791-1.942.386-4.031 3.529-5.535 3.144-1.504 7.511-2.067 11.569-1.689l1.971-.943-1.971-.943c-4.058.379-8.425-.184-11.569-1.689-3.134-1.5-4.313-3.58-3.536-5.517z"
        fill="url(#hero-1-svg-prefix__paint53_linear)"
      />
      <motion.ellipse
        style={{ x: xDrone3 }}
        opacity={0.6}
        cx={324.5}
        cy={88.01}
        rx={10.472}
        ry={5.01}
        fill="url(#hero-1-svg-prefix__paint54_linear)"
      />
      <motion.path
        style={{ x: xDrone3 }}
        fillRule="evenodd"
        clipRule="evenodd"
        d="M321.508 87.995a2.992 2.992 0 015.984.015v.016c-.018.783-1.351 1.416-2.992 1.416s-2.974-.633-2.992-1.416v-.01-.006-.015z"
        fill="url(#hero-1-svg-prefix__paint55_linear)"
      />
      <motion.path
        style={{ x: xDrone3 }}
        fillRule="evenodd"
        clipRule="evenodd"
        d="M327.492 106.219h-5.984l1.795 13.132v.002c0 .678.536 1.227 1.197 1.227.66 0 1.196-.549 1.196-1.227l1.796-13.134z"
        fill="#4657CD"
      />
      <motion.ellipse
        style={{ x: xDrone3 }}
        opacity={0.6}
        cx={324.5}
        cy={106.219}
        rx={10.472}
        ry={5.01}
        fill="url(#hero-1-svg-prefix__paint56_linear)"
      />
      <motion.path
        style={{ x: xDrone3 }}
        fillRule="evenodd"
        clipRule="evenodd"
        d="M321.508 106.217a2.992 2.992 0 115.984 0v.002c0 .791-1.34 1.432-2.992 1.432-1.653 0-2.992-.641-2.992-1.432v-.002z"
        fill="url(#hero-1-svg-prefix__paint57_linear)"
      />
      <motion.path
        style={{ x: xDrone3 }}
        fillRule="evenodd"
        clipRule="evenodd"
        d="M346.52 97.115h-5.983l1.795 12.585v.001c0 .65.535 1.176 1.196 1.176.661 0 1.197-.526 1.197-1.176l1.795-12.586z"
        fill="#4657CD"
      />
      <motion.ellipse
        style={{ x: xDrone3 }}
        opacity={0.6}
        cx={343.528}
        cy={97.115}
        rx={10.472}
        ry={5.01}
        fill="url(#hero-1-svg-prefix__paint58_linear)"
      />
      <motion.path
        style={{ x: xDrone3 }}
        fillRule="evenodd"
        clipRule="evenodd"
        d="M340.537 97.109a2.992 2.992 0 015.983 0V97.122c-.007.787-1.344 1.424-2.991 1.424-1.648 0-2.985-.637-2.992-1.424v-.004-.003-.006z"
        fill="url(#hero-1-svg-prefix__paint59_linear)"
      />
      <motion.path
        style={{ x: xDrone3 }}
        fillRule="evenodd"
        clipRule="evenodd"
        d="M308.464 97.115h-5.984l1.795 12.586c0 .65.536 1.176 1.197 1.176s1.197-.526 1.197-1.176l1.795-12.586z"
        fill="#4657CD"
      />
      <motion.ellipse
        style={{ x: xDrone3 }}
        opacity={0.6}
        cx={305.472}
        cy={97.115}
        rx={10.472}
        ry={5.01}
        fill="url(#hero-1-svg-prefix__paint60_linear)"
      />
      <motion.path
        style={{ x: xDrone3 }}
        fillRule="evenodd"
        clipRule="evenodd"
        d="M302.48 97.109a2.992 2.992 0 015.984 0V97.122c-.008.787-1.345 1.424-2.992 1.424-1.648 0-2.984-.637-2.992-1.424v-.004-.003-.006z"
        fill="url(#hero-1-svg-prefix__paint61_linear)"
      />
      {/* Drone 3 End */}
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M317.056 231.425c-10.333-1.086-18.709 6.456-18.709 16.845s8.376 19.691 18.709 20.777c10.332 1.086 18.708-6.455 18.708-16.845 0-10.389-8.376-19.691-18.708-20.777zm-24.945 16.189c0-13.852 11.168-23.907 24.945-22.46C330.832 226.602 342 239.006 342 252.858s-11.168 23.908-24.944 22.46c-13.777-1.448-24.945-13.851-24.945-27.704z"
        fill="#FFDDDA"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M335.764 252.203c0-10.39-8.376-19.692-18.708-20.778-10.333-1.086-18.709 6.456-18.709 16.845s8.376 19.692 18.709 20.778c1.722.181 3.118 1.731 3.118 3.463 0 1.731-1.396 2.988-3.118 2.807-13.777-1.448-24.945-13.851-24.945-27.703 0-13.853 11.168-23.908 24.945-22.46C330.832 226.603 342 239.006 342 252.858c0 1.732-1.396 2.989-3.118 2.808-1.722-.181-3.118-1.732-3.118-3.463z"
        fill="#FF9E95"
      />
      <defs>
        <linearGradient
          id="hero-1-svg-prefix__paint0_linear"
          x1={112.5}
          y1={375.778}
          x2={104.926}
          y2={588.713}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#E0E3FE" />
          <stop offset={1} stopColor="#fff" />
        </linearGradient>
        <linearGradient
          id="hero-1-svg-prefix__paint1_linear"
          x1={337.5}
          y1={375.778}
          x2={373.312}
          y2={577.738}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#D1D6FD" />
          <stop offset={1} stopColor="#fff" />
        </linearGradient>
        <linearGradient
          id="hero-1-svg-prefix__paint2_linear"
          x1={50}
          y1={0}
          x2={49.995}
          y2={103}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#F9CF7A" />
          <stop offset={1} stopColor="#FFEBC4" />
        </linearGradient>
        <linearGradient
          id="hero-1-svg-prefix__paint3_linear"
          x1={166.286}
          y1={354.471}
          x2={158.315}
          y2={394.379}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#E0E3FE" />
          <stop offset={1} stopColor="#fff" />
        </linearGradient>
        <linearGradient
          id="hero-1-svg-prefix__paint4_linear"
          x1={274.286}
          y1={350.958}
          x2={290.132}
          y2={403.525}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#D1D6FD" />
          <stop offset={1} stopColor="#fff" />
        </linearGradient>
        <linearGradient
          id="hero-1-svg-prefix__paint5_linear"
          x1={166.286}
          y1={351.804}
          x2={158.315}
          y2={391.712}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#C0C8FD" />
          <stop offset={1} stopColor="#fff" />
        </linearGradient>
        <linearGradient
          id="hero-1-svg-prefix__paint6_linear"
          x1={274.286}
          y1={348.291}
          x2={290.132}
          y2={400.858}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#A6B1FF" />
          <stop offset={1} stopColor="#fff" />
        </linearGradient>
        <linearGradient
          id="hero-1-svg-prefix__paint7_linear"
          x1={166.286}
          y1={331.865}
          x2={158.315}
          y2={371.772}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#E0E3FE" />
          <stop offset={1} stopColor="#fff" />
        </linearGradient>
        <linearGradient
          id="hero-1-svg-prefix__paint8_linear"
          x1={274.286}
          y1={328.351}
          x2={290.132}
          y2={380.919}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#D1D6FD" />
          <stop offset={1} stopColor="#fff" />
        </linearGradient>
        <linearGradient
          id="hero-1-svg-prefix__paint9_linear"
          x1={166.286}
          y1={329.198}
          x2={158.315}
          y2={369.106}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#C0C8FD" />
          <stop offset={1} stopColor="#fff" />
        </linearGradient>
        <linearGradient
          id="hero-1-svg-prefix__paint10_linear"
          x1={274.286}
          y1={325.685}
          x2={290.132}
          y2={378.252}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#A6B1FF" />
          <stop offset={1} stopColor="#fff" />
        </linearGradient>
        <linearGradient
          id="hero-1-svg-prefix__paint11_linear"
          x1={166.286}
          y1={309.198}
          x2={158.315}
          y2={349.106}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#E0E3FE" />
          <stop offset={1} stopColor="#fff" />
        </linearGradient>
        <linearGradient
          id="hero-1-svg-prefix__paint12_linear"
          x1={274.286}
          y1={305.685}
          x2={290.132}
          y2={358.252}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#D1D6FD" />
          <stop offset={1} stopColor="#fff" />
        </linearGradient>
        <linearGradient
          id="hero-1-svg-prefix__paint13_linear"
          x1={56.667}
          y1={0}
          x2={59.305}
          y2={21.304}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#A6B1FF" />
          <stop offset={1} stopColor="#fff" />
        </linearGradient>
        <linearGradient
          id="hero-1-svg-prefix__paint14_linear"
          x1={56.667}
          y1={0}
          x2={59.305}
          y2={21.304}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#A6B1FF" />
          <stop offset={1} stopColor="#fff" />
        </linearGradient>
        <linearGradient
          id="hero-1-svg-prefix__paint15_linear"
          x1={43.636}
          y1={-1.129}
          x2={45.701}
          y2={34.163}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#B8C1FE" />
          <stop offset={1} stopColor="#ECEEFE" />
        </linearGradient>
        <linearGradient
          id="hero-1-svg-prefix__paint16_linear"
          x1={43.636}
          y1={-1.129}
          x2={45.701}
          y2={34.163}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#B8C1FE" />
          <stop offset={1} stopColor="#ECEEFE" />
        </linearGradient>
        <linearGradient
          id="hero-1-svg-prefix__paint17_linear"
          x1={197.999}
          y1={260.291}
          x2={174.025}
          y2={289.453}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#BCFFF0" />
          <stop offset={1} stopColor="#AAF3E3" />
        </linearGradient>
        <linearGradient
          id="hero-1-svg-prefix__paint18_linear"
          x1={254.666}
          y1={258.958}
          x2={274.621}
          y2={293.519}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#ABF5E4" />
          <stop offset={1} stopColor="#82DEC8" />
        </linearGradient>
        <linearGradient
          id="hero-1-svg-prefix__paint19_linear"
          x1={24.523}
          y1={342.921}
          x2={31.961}
          y2={354.274}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#69F1B0" />
          <stop offset={0.894} stopColor="#46C489" />
        </linearGradient>
        <linearGradient
          id="hero-1-svg-prefix__paint20_linear"
          x1={33.289}
          y1={350.411}
          x2={44.769}
          y2={361.459}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#47C689" />
          <stop offset={1} stopColor="#2A9681" />
        </linearGradient>
        <linearGradient
          id="hero-1-svg-prefix__paint21_linear"
          x1={408.523}
          y1={342.921}
          x2={415.961}
          y2={354.274}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#69F1B0" />
          <stop offset={0.894} stopColor="#46C489" />
        </linearGradient>
        <linearGradient
          id="hero-1-svg-prefix__paint22_linear"
          x1={417.289}
          y1={350.411}
          x2={428.769}
          y2={361.459}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#47C689" />
          <stop offset={1} stopColor="#2A9681" />
        </linearGradient>
        <linearGradient
          id="hero-1-svg-prefix__paint23_linear"
          x1={216.523}
          y1={364.921}
          x2={223.961}
          y2={376.274}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#69F1B0" />
          <stop offset={0.894} stopColor="#46C489" />
        </linearGradient>
        <linearGradient
          id="hero-1-svg-prefix__paint24_linear"
          x1={225.289}
          y1={372.411}
          x2={236.769}
          y2={383.459}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#47C689" />
          <stop offset={1} stopColor="#2A9681" />
        </linearGradient>
        <linearGradient
          id="hero-1-svg-prefix__paint25_linear"
          x1={23.613}
          y1={0.381}
          x2={26.873}
          y2={57.414}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FF8074" />
          <stop offset={1} stopColor="#FFCEC9" />
        </linearGradient>
        <linearGradient
          id="hero-1-svg-prefix__paint26_linear"
          x1={158.104}
          y1={63.798}
          x2={132.926}
          y2={85.174}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#837DFF" />
          <stop offset={1} stopColor="#6059E3" />
        </linearGradient>
        <linearGradient
          id="hero-1-svg-prefix__paint27_linear"
          x1={163.5}
          y1={93}
          x2={153.021}
          y2={97.878}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#BCC3FF" />
          <stop offset={1} stopColor="#fff" />
        </linearGradient>
        <linearGradient
          id="hero-1-svg-prefix__paint28_linear"
          x1={193.053}
          y1={64.484}
          x2={216.195}
          y2={86.258}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#6159FF" />
          <stop offset={1} stopColor="#372FD9" />
        </linearGradient>
        <linearGradient
          id="hero-1-svg-prefix__paint29_linear"
          x1={174.023}
          y1={13.418}
          x2={163.103}
          y2={54.457}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#E0E3FE" />
          <stop offset={1} stopColor="#fff" />
        </linearGradient>
        <linearGradient
          id="hero-1-svg-prefix__paint30_linear"
          x1={174}
          y1={0}
          x2={158.143}
          y2={18.617}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#CED3FF" />
          <stop offset={1} stopColor="#F9F9FF" />
        </linearGradient>
        <linearGradient
          id="hero-1-svg-prefix__paint31_linear"
          x1={174}
          y1={3.931}
          x2={189.66}
          y2={15.363}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#707FE9" />
          <stop offset={1} stopColor="#25318A" />
        </linearGradient>
        <linearGradient
          id="hero-1-svg-prefix__paint32_linear"
          x1={174}
          y1={35.184}
          x2={158.143}
          y2={53.801}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#CED3FF" />
          <stop offset={1} stopColor="#F9F9FF" />
        </linearGradient>
        <linearGradient
          id="hero-1-svg-prefix__paint33_linear"
          x1={174}
          y1={39.079}
          x2={189.706}
          y2={50.496}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#707FE9" />
          <stop offset={1} stopColor="#25318A" />
        </linearGradient>
        <linearGradient
          id="hero-1-svg-prefix__paint34_linear"
          x1={210.766}
          y1={17.591}
          x2={194.91}
          y2={36.209}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#CED3FF" />
          <stop offset={1} stopColor="#F9F9FF" />
        </linearGradient>
        <linearGradient
          id="hero-1-svg-prefix__paint35_linear"
          x1={210.767}
          y1={21.505}
          x2={226.449}
          y2={32.93}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#707FE9" />
          <stop offset={1} stopColor="#25318A" />
        </linearGradient>
        <linearGradient
          id="hero-1-svg-prefix__paint36_linear"
          x1={137.233}
          y1={17.591}
          x2={121.376}
          y2={36.209}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#CED3FF" />
          <stop offset={1} stopColor="#F9F9FF" />
        </linearGradient>
        <linearGradient
          id="hero-1-svg-prefix__paint37_linear"
          x1={137.234}
          y1={21.505}
          x2={152.916}
          y2={32.93}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#707FE9" />
          <stop offset={1} stopColor="#25318A" />
        </linearGradient>
        <linearGradient
          id="hero-1-svg-prefix__paint38_linear"
          x1={254.753}
          y1={204.899}
          x2={245.716}
          y2={212.571}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#837DFF" />
          <stop offset={1} stopColor="#6059E3" />
        </linearGradient>
        <linearGradient
          id="hero-1-svg-prefix__paint39_linear"
          x1={256.691}
          y1={215.381}
          x2={252.929}
          y2={217.131}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#BCC3FF" />
          <stop offset={1} stopColor="#fff" />
        </linearGradient>
        <linearGradient
          id="hero-1-svg-prefix__paint40_linear"
          x1={267.298}
          y1={205.145}
          x2={275.604}
          y2={212.961}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#6159FF" />
          <stop offset={1} stopColor="#372FD9" />
        </linearGradient>
        <linearGradient
          id="hero-1-svg-prefix__paint41_linear"
          x1={260.468}
          y1={186.816}
          x2={256.548}
          y2={201.546}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#E0E3FE" />
          <stop offset={1} stopColor="#fff" />
        </linearGradient>
        <linearGradient
          id="hero-1-svg-prefix__paint42_linear"
          x1={260.459}
          y1={182}
          x2={254.768}
          y2={188.682}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#CED3FF" />
          <stop offset={1} stopColor="#F9F9FF" />
        </linearGradient>
        <linearGradient
          id="hero-1-svg-prefix__paint43_linear"
          x1={260.459}
          y1={183.411}
          x2={266.08}
          y2={187.514}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#707FE9" />
          <stop offset={1} stopColor="#25318A" />
        </linearGradient>
        <linearGradient
          id="hero-1-svg-prefix__paint44_linear"
          x1={260.459}
          y1={194.628}
          x2={254.768}
          y2={201.311}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#CED3FF" />
          <stop offset={1} stopColor="#F9F9FF" />
        </linearGradient>
        <linearGradient
          id="hero-1-svg-prefix__paint45_linear"
          x1={260.459}
          y1={196.027}
          x2={266.096}
          y2={200.125}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#707FE9" />
          <stop offset={1} stopColor="#25318A" />
        </linearGradient>
        <linearGradient
          id="hero-1-svg-prefix__paint46_linear"
          x1={273.656}
          y1={188.314}
          x2={267.964}
          y2={194.996}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#CED3FF" />
          <stop offset={1} stopColor="#F9F9FF" />
        </linearGradient>
        <linearGradient
          id="hero-1-svg-prefix__paint47_linear"
          x1={273.656}
          y1={189.719}
          x2={279.285}
          y2={193.819}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#707FE9" />
          <stop offset={1} stopColor="#25318A" />
        </linearGradient>
        <linearGradient
          id="hero-1-svg-prefix__paint48_linear"
          x1={247.262}
          y1={188.314}
          x2={241.571}
          y2={194.996}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#CED3FF" />
          <stop offset={1} stopColor="#F9F9FF" />
        </linearGradient>
        <linearGradient
          id="hero-1-svg-prefix__paint49_linear"
          x1={247.262}
          y1={189.719}
          x2={252.891}
          y2={193.819}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#707FE9" />
          <stop offset={1} stopColor="#25318A" />
        </linearGradient>
        <linearGradient
          id="hero-1-svg-prefix__paint50_linear"
          x1={316.273}
          y1={116.018}
          x2={303.242}
          y2={127.081}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#837DFF" />
          <stop offset={1} stopColor="#6059E3" />
        </linearGradient>
        <linearGradient
          id="hero-1-svg-prefix__paint51_linear"
          x1={319.066}
          y1={131.132}
          x2={313.642}
          y2={133.656}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#BCC3FF" />
          <stop offset={1} stopColor="#fff" />
        </linearGradient>
        <linearGradient
          id="hero-1-svg-prefix__paint52_linear"
          x1={334.361}
          y1={116.373}
          x2={346.337}
          y2={127.642}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#6159FF" />
          <stop offset={1} stopColor="#372FD9" />
        </linearGradient>
        <linearGradient
          id="hero-1-svg-prefix__paint53_linear"
          x1={324.513}
          y1={89.945}
          x2={318.861}
          y2={111.184}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#E0E3FE" />
          <stop offset={1} stopColor="#fff" />
        </linearGradient>
        <linearGradient
          id="hero-1-svg-prefix__paint54_linear"
          x1={324.5}
          y1={83}
          x2={316.294}
          y2={92.635}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#CED3FF" />
          <stop offset={1} stopColor="#F9F9FF" />
        </linearGradient>
        <linearGradient
          id="hero-1-svg-prefix__paint55_linear"
          x1={324.5}
          y1={85.034}
          x2={332.605}
          y2={90.951}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#707FE9" />
          <stop offset={1} stopColor="#25318A" />
        </linearGradient>
        <linearGradient
          id="hero-1-svg-prefix__paint56_linear"
          x1={324.5}
          y1={101.209}
          x2={316.294}
          y2={110.844}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#CED3FF" />
          <stop offset={1} stopColor="#F9F9FF" />
        </linearGradient>
        <linearGradient
          id="hero-1-svg-prefix__paint57_linear"
          x1={324.5}
          y1={103.225}
          x2={332.628}
          y2={109.134}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#707FE9" />
          <stop offset={1} stopColor="#25318A" />
        </linearGradient>
        <linearGradient
          id="hero-1-svg-prefix__paint58_linear"
          x1={343.528}
          y1={92.105}
          x2={335.322}
          y2={101.74}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#CED3FF" />
          <stop offset={1} stopColor="#F9F9FF" />
        </linearGradient>
        <linearGradient
          id="hero-1-svg-prefix__paint59_linear"
          x1={343.529}
          y1={94.13}
          x2={351.645}
          y2={100.043}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#707FE9" />
          <stop offset={1} stopColor="#25318A" />
        </linearGradient>
        <linearGradient
          id="hero-1-svg-prefix__paint60_linear"
          x1={305.472}
          y1={92.105}
          x2={297.265}
          y2={101.74}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#CED3FF" />
          <stop offset={1} stopColor="#F9F9FF" />
        </linearGradient>
        <linearGradient
          id="hero-1-svg-prefix__paint61_linear"
          x1={305.472}
          y1={94.13}
          x2={313.588}
          y2={100.043}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#707FE9" />
          <stop offset={1} stopColor="#25318A" />
        </linearGradient>
        <clipPath id="hero-1-svg-prefix__clip0">
          <rect
            width={192.076}
            height={115.426}
            rx={2}
            transform="matrix(.99307 -.11754 0 1 9.882 198.188)"
            fill="#fff"
          />
        </clipPath>
      </defs>
    </motion.svg>
  )
}

export default SvgComponent

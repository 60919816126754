import * as React from "react"

function SvgComponent(props) {
  return (
    <svg
      width={90}
      height={90}
      viewBox="0 0 90 90"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path fill="#F4F6F8" d="M0 0h90v90H0z" />
    </svg>
  )
}

export default SvgComponent
